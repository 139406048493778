import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { head } from 'lodash';
import useAPI from '../api/useAPI';
import { getDeliveryPartition } from '../redux/config/selectors';
import { getProfileUUID } from '../redux/profile/selectors';
import StoragesConstants from '../redux/storages/constants';

export default function useUserRootEntity() {
  const dispatch = useDispatch();

  const actor = useSelector(getProfileUUID);
  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    getRootUser,
    readRootUser,
    getDefaultDepartment,
  } = useAPI();

  const initFunc = async () => {
    const res = await readRootUser();
    if (res?.data?.length) {
      dispatch({
        type: StoragesConstants.SET_ROOT_USER,
        payload: head(res?.data),
      });
      return;
    }
    const defaultDepartment = await getDefaultDepartment();

    getRootUser(actor, defaultDepartment?.uuid);
  };

  useEffect(() => {
    if (partitionUUID && actor) {
      initFunc();
    }
  }, [partitionUUID, JSON.stringify(actor)]);

  return null;
}
