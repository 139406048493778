import React, { useEffect, useState } from 'react';
import { Divider, Flex } from 'antd';
import { object } from 'prop-types';
import { get, head } from 'lodash';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import EntityStatusForCard from '../common/EntityStatusForCard';
import EntityDescriptionForCard from '../common/EntityDescriptionForCard';
import useAPI from '../../../../api/useAPI';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import { translateTypeDay } from '../../../user/dayOff/utils';

function convertTimeFormat(input) {
  const regex = /(\d+)h(\d+)m/;
  const match = input?.match?.(regex);

  if (match) {
    const hours = match[1].padStart(2, '0');
    const minutes = match[2].padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return '';
}

export default function UserArtifactView({
  artifact,
}) {
  const dispatch = useDispatch();

  const {
    getMonthlyReport,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const {
    uuid,
    actor,
  } = artifact || {};

  const [reports, setReports] = useState({});
  const [today, setToday] = useState(null);

  const {
    previous: previousReport = {},
  } = reports || {};

  const initFunc = async () => {
    const res = await getMonthlyReport(uuid, {
      actor,
      depth: 0,
      params: {
        date: [
          dayjs().format('YYYY-MM'),
          dayjs().subtract(1, 'month').format('YYYY-MM'),
        ],
      },
    });

    const currentMonth = res?.data?.find((el) => el?.params?.date === dayjs().format('YYYY-MM'));
    const previousMonth = res?.data?.find((el) => el?.params?.date === dayjs().subtract(1, 'month').format('YYYY-MM'));

    const todayReport = currentMonth?.params?.days?.find((el) => head(el?.rangeDates) === dayjs().format('YYYY-MM-DD'));

    setToday(todayReport);
    setReports({
      current: currentMonth,
      previous: previousMonth,
    });
  };

  useEffect(() => {
    if (partitionUUID && actor) {
      initFunc();
    }
  }, [partitionUUID, actor]);

  return (
    <Flex
      vertical
      className="w-full h-full"
    >

      <Flex>
        <Flex
          align="center"
          className="mx-12"
        >
          <span
            className="mr-2 text-base"
          >
            Имя:
          </span>
          <span
            className="text-blue-500"
          >
            {artifact?.uinfo?.first_name}
            {' '}
            {artifact?.uinfo?.last_name}
          </span>
        </Flex>
      </Flex>
      <Divider className="my-2" />
      <Flex
        className="mx-12"
        align="start"
      >
        <span
          className="mr-2 text-base"
        >
          Дневной отчет (сегодня):
        </span>
        {today
          ? (
            <Flex
              className="text-base"
            >
              <span
                className="mr-6 text-blue-500"
              >
                {translateTypeDay[today?.type]}
                {' '}
                (
                {convertTimeFormat(today?.startWork)}
                -
                {convertTimeFormat(today?.finishWork)}
                )
              </span>
            </Flex>
          )
          : (
            <span
              className="mr-2 text-base text-red-500"
            >
              Нет отчета
            </span>
          )}
      </Flex>
      <Divider className="my-2" />

      <Flex
        className="mx-12"
        align="start"
        vertical
      >
        <span
          className="mr-2 text-base"
        >
          Отчет за предыдущий месяц:
          {previousReport?.params?.status === 'auto' && (
          <span className="ml-2 text-blue-500">
            Автоматический
          </span>
          )}
          {previousReport?.params?.status === 'submitted' && (
          <span className="ml-2 text-blue-500">
            Пользовательский
          </span>
          )}
          {!previousReport?.params?.status && <span className="ml-2 text-red-500">Нет отчета</span>}
        </span>
        {previousReport?.params?.statistics && (
          <Flex
            justify="space-between"
            className="w-full text-blue-500"
          >
            <Flex
              vertical
            >
              <span>
                Всего проектных часов:
                {' '}
                {previousReport?.params?.statistics?.totalWorks}
              </span>
              <span>
                Всего нерабочих часов:
                {' '}
                {previousReport?.params?.statistics?.totalDayOff}
              </span>
            </Flex>
            <Flex
              vertical
            >
              <span>
                Всего больничных часов:
                {' '}
                {previousReport?.params?.statistics?.sickday}
              </span>
              <span>
                Всего отгулов:
                {' '}
                {previousReport?.params?.statistics?.dayoff}
              </span>
            </Flex>
            <Flex
              vertical
            >
              <span>
                Бенч:
                {' '}
                {previousReport?.params?.statistics?.totalBenches}
              </span>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

UserArtifactView.propTypes = {
  artifact: object,
};
