import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';
import { concat, get } from 'lodash';

import useAPI from '../../api/useAPI';

import {
  computeStatisticsForReport,
  enumerateDaysBetweenDates,
} from '../../utils/reportUtils';

import { getProfileInfo } from '../../redux/profile/selectors';
import {
  getMonthlyStorageInfo,
  getMonthlyStorageUUID,
  getUserRootInfo,
  getVacationStorageUUID,
} from '../../redux/storages/selectors';

export default function CheckLastMonthReport() {
  const {
    updateMonthlyReport,
    getListOfVacations,
    getOrCreateMonthlyReport,
  } = useAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const myProfileData = useSelector(getProfileInfo);
  const userInfo = useSelector(getUserRootInfo);

  const lastMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const startMonth = dayjs(lastMonth).startOf('month').format('YYYY-MM-DD');
  const endMonth = dayjs(lastMonth).endOf('month').format('YYYY-MM-DD');

  const actor = myProfileData?.uuid;

  const getLastMonthReport = async () => {
    const res = await getOrCreateMonthlyReport(monthlyStorageUUID, {
      actor,
      params: {
        date: lastMonth,
      },
    }, [
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_REQUEST',
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_SUCCESS',
      'GET_MONTHLY_REPORT_FOR_AUTO_REPORT_FAILURE',
    ]);
    return res;
  };

  const getVacations = async () => {
    const res = await getListOfVacations(vacationStorageUUID, {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(startMonth).format('YYYY-MM-DD'),
          dayjs(endMonth).format('YYYY-MM-DD'),
        ),
      },
    }, [
      'GET_VACATIONS_FOR_AUTO_REPORT_REQUEST',
      'GET_VACATIONS_FOR_AUTO_REPORT_SUCCESS',
      'GET_VACATIONS_FOR_AUTO_REPORT_FAILURE',
    ]);
    return get(res, 'data', []);
  };

  const initFunc = async () => {
    const report = await getLastMonthReport();
    const reportStatus = get(report, ['params', 'status'], '');

    if (!report || reportStatus === 'auto' || reportStatus === 'submitted') {
      return;
    }

    const vacations = await getVacations();

    const records = concat(get(report, 'params.days', []), vacations);

    const data = computeStatisticsForReport(records, startMonth, endMonth);

    await updateMonthlyReport({
      entity_type: 'monthlyReport',
      entity_uuid: report?.uuid,
      actor,
      params: {
        status: 'auto',
        statistics: {
          ...data,
          created: dayjs().toString(),
        },
      },
      logs: [],
    });
  };

  useEffect(() => {
    if (userInfo?.actor === actor
      && monthlyStorageUUID
      && monthlyStorageInfo?.actor === actor
      && vacationStorageUUID) {
      initFunc();
    }
  }, [
    userInfo?.actor,
    monthlyStorageInfo?.actor,
    actor,
    monthlyStorageUUID,
    vacationStorageUUID,
  ]);

  return null;
}
