import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useHistory,
} from 'react-router-dom';

import { array, string } from 'prop-types';
import dayjs from 'dayjs';
import {
  capitalize, get, head, isNil,
} from 'lodash';
import cn from 'classnames';

import { Flex } from 'antd';
import useAPI from '../../../../api/useAPI';

import { getDeliveryPartition } from '../../../../redux/config/selectors';

import { translateTypeDay } from '../../../user/dayOff/utils';
import { compareActorUUIDs, convertTimeFormat } from './utils';

import BaseTable from '../common/BaseTable/BaseTable';
import EmployeeNameForDepartment from './EmployeeNameForDepartment';
import ArtifactStatusForDepartment from './ArtifactStatusForDepartment';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';
import { getListAndReadEntities } from '../../../../api/actions/entity';

export default function ReportsForDepartment({
  departmentUUID = '',
  leader = '',
  deputy = '',
}) {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  const activeArtifact = query.get('artifact');

  const {
    getMonthlyReport,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [dataTable, setDataTable] = useState([]);

  const [users, setUsers] = useState([]);

  const today = dayjs().format('YYYY-MM-DD');

  const currentMonth = dayjs().subtract(1, 'month').format('YYYY-MM');
  const previousMonth = dayjs().subtract(2, 'month').format('YYYY-MM');
  const beforeLastMonth = dayjs().subtract(3, 'month').format('YYYY-MM');

  const initEmployeesFunc = async () => {
    const res = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    setUsers(res?.data ?? []);
  };

  const initMonthlyReports = async () => {
    if (!users?.length) {
      setDataTable([]);
      return;
    }

    const res = await getMonthlyReport(partitionUUID, {
      depth: 0,
      params: {
        date: [currentMonth, previousMonth, beforeLastMonth],
      },
    });

    const reports = res?.data ?? [];

    setDataTable(users.map((user) => {
      const beforeLastRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === beforeLastMonth);
      const previousRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === previousMonth);
      const currentRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === currentMonth);

      return {
        ...user,
        currentMonthRep: currentRep ?? null,
        prevMonthRep: previousRep ?? null,
        beforeLastMonthRep: beforeLastRep ?? null,
      };
    }));
  };

  const columns = [
    {
      title: 'Имя',
      key: 'name',
      dataIndex: ['uinfo'],
      width: '30%',
      render: (cell, row) => (
        <EmployeeNameForDepartment
          user={row}
          deputy={deputy}
          leader={leader}
        />
      ),
    },
    {
      title: '',
      key: 'label',
      dataIndex: ['label'],
      width: '20%',
      render: () => (
        <Flex
          vertical
          className="text-base text-blue-500"
        >
          <span>
            Клиентский пр.
          </span>
          <span>
            Внутренний пр.
          </span>
          <span>
            Всего рабочих
          </span>
        </Flex>
      ),
    },
    {
      title: <Flex justify="center">{capitalize(dayjs().subtract(1, 'month').format('MMMM'))}</Flex>,
      key: 'currentMonthRep',
      dataIndex: ['currentMonthRep'],
      width: '15%',
      render: (cell) => (cell?.params?.status ? (
        <Flex
          className="w-full h-full text-green-500"
          align="center"
          justify="center"
          vertical
        >
          <Flex>
            {cell?.params?.statistics?.clientWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.internalWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.totalWorks ?? 0}
          </Flex>
        </Flex>
      )
        : (
          <Flex
            align="center"
            justify="center"
            className="text-red-500"
          >
            Отсутствует
          </Flex>
        )),
    },
    {
      title: <Flex justify="center">{capitalize(dayjs().subtract(2, 'month').format('MMMM'))}</Flex>,
      key: 'prevMonthRep',
      dataIndex: ['prevMonthRep'],
      width: '15%',
      render: (cell) => (cell?.params?.status ? (
        <Flex
          className="w-full h-full text-green-500"
          align="center"
          justify="center"
          vertical
        >
          <Flex>
            {cell?.params?.statistics?.clientWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.internalWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.totalWorks ?? 0}
          </Flex>
        </Flex>
      )
        : (
          <Flex
            align="center"
            justify="center"
            className="text-red-500"
          >
            Отсутствует
          </Flex>
        )),
    },
    {
      title: <Flex justify="center">{capitalize(dayjs().subtract(3, 'month').format('MMMM'))}</Flex>,
      key: 'beforeLastRep',
      dataIndex: ['beforeLastRep'],
      width: '15%',
      render: (cell) => (cell?.params?.status ? (
        <Flex
          className="w-full h-full text-green-500"
          align="center"
          justify="center"
          vertical
        >
          <Flex>
            {cell?.params?.statistics?.clientWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.internalWork ?? 0}
          </Flex>
          <Flex>
            {cell?.params?.statistics?.totalWorks ?? 0}
          </Flex>
        </Flex>
      ) : (
        <Flex
          align="center"
          justify="center"
          className="text-red-500"
        >
          Отсутствует
        </Flex>
      )),
    }];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      query.set('artifact', uuid);

      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  useEffect(() => {
    if (departmentUUID) {
      initEmployeesFunc();
    }
  }, [departmentUUID]);

  useEffect(() => {
    if (departmentUUID) {
      initMonthlyReports();
    }
  }, [JSON.stringify(users), departmentUUID]);

  return (
    <BaseTable
      data={dataTable}
      columns={columns}
      rowClassName={rowClassName}
      size="small"
      rowKey="uuid"
      onRow={onRow}
      className={cn('w-full h-full !mb-3')}
      useCustomPagination
      disablePagination
    />
  );
}

ReportsForDepartment.propTypes = {
  departmentUUID: string,
  leader: string,
  deputy: string,
};
