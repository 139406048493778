import React, { useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Flex, Input, Modal, Segmented,
  Upload,
  message,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { func, object, string } from 'prop-types';
import { get, head } from 'lodash';
import cn from 'classnames';
import { createArtifact } from '../../../../redux/departments/actions/artifactsActions';
import EntityDepartmentForArtifact from '../common/EntityDepartmentForArtifact';
import AntUploaderFiles from '../../../../components/commonComponents/AntUploaderFiles';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import AssigningUserCard from '../users/AssigningUserCard';
import useAPI from '../../../../api/useAPI';

const { TextArea } = Input;

export default function AddEmployeeButton({
  parent,
  showUserCreateButton,
  artifactsStorage,
  defaultDepartmentUUID = '',
  onCreateArtifactCallback,
}) {
  const partitionUUID = useSelector(getDeliveryPartition);

  const [department, setDepartment] = useState('');
  const [user, setUser] = useState({});

  const [defaultDepartment, setDefaultDepartment] = useState({});

  const [isOpen, setIsOpen] = useState(false);

  const onOpenModal = () => setIsOpen(true);
  const onCloseModal = () => setIsOpen(false);

  const {
    getDefaultDepartment,
    updateUserEntity,
    createEntityPermissions,
  } = useAPI();

  const onCreateArtifact = async () => {
    createEntityPermissions({
      data: {
        entity_uuid: department,
        data: [{
          actor: user?.actor,
          create: true,
          list: true,
          read: true,
          update: false,
          delete: false,
          set: false,
        }],
      },
    });
    onCloseModal();
    await updateUserEntity(user?.value, {
      parent: department,
    });

    if (onCreateArtifactCallback) {
      onCreateArtifactCallback(user?.value);
    }
  };

  const initFunc = async () => {
    const res = await getDefaultDepartment();
    setDefaultDepartment(res);
  };

  useEffect(() => {
    if (isOpen) {
      setDepartment(defaultDepartmentUUID);
    } else {
      setUser({});
      setDepartment(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (partitionUUID) {
      initFunc();
    }
  }, [partitionUUID]);

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModal}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Добавить сотрудника
      </Button>
      <Modal
        open={isOpen}
        title="Добавление сотрудника"
        onCancel={onCloseModal}
        footer={null}
        centered
        width={900}
        destroyOnClose
      >
        <Flex align="center" justify="space-between" className="mb-3">
          <EntityDepartmentForArtifact
            department={department}
            artifactsStorage={artifactsStorage}
            onEditCallback={({ department: dep }) => setDepartment(dep)}
          />
        </Flex>
        <Flex
          vertical
        >
          <span
            className="text-base mb-2 -mt-2"
          >
            Пользователь:
          </span>
          <Flex
            className="w-full mt-2"
            justify="space-between"
          >
            <Flex>
              <AssigningUserCard
                title=""
                style={{ width: 500 }}
                size={50}
                popoverChildTitle={(
                  <span
                    className="mr-2 text-stone-500"
                  >
                    Руководитель:
                  </span>
                )}
                parent={defaultDepartment?.uuid}
                selected={user}
                onUserSelect={setUser}
              />
            </Flex>
            <Flex
              vertical
              className="ml-2 h-full"
            >
              <Alert
                type="info"
                className="-mt-1"
                message="Памятка"
                description={(
                  <Flex
                    vertical
                  >
                    <p className="!text-base">
                      - Для назначения пользователя в отдел необходимо выбрать его в списке.
                    </p>
                    <p className="!text-base">
                      - Для добавления доступны только пользователи,
                      которые не состоят в другом отделе.
                    </p>
                  </Flex>
              )}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          className="mt-3"
          justify="end"
        >
          <Button
            className="mr-2 !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white"
            onClick={onCloseModal}
          >
            Отмена
          </Button>
          <Button
            disabled={(!user?.actor || !department)}
            className={cn('border-green-500 text-green-500 hover:bg-green-500 hover:!text-white', {
              'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white': !user?.actor || !department,
            })}
            onClick={onCreateArtifact}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
    </>
  );
}

AddEmployeeButton.propTypes = {
  parent: string,
  artifactsStorage: string,
  defaultDepartmentUUID: string,
  onCreateArtifactCallback: func,
};
