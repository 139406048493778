import React, {
  useEffect,
  useState,
} from 'react';
import {
  Flex,
  Tabs,
} from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  array, func, object, string,
} from 'prop-types';
import { getSingleDepartment } from '../../../redux/departments/actions/departmentsActions';
import { getDepartment, getDepartmentUUID } from '../../../redux/departments/selectors/departmentsSelector';
import PageWrapper from '../../../components/PageWrapper';
import { getSingleArtifact } from '../../../redux/departments/actions/artifactsActions';
import ArtifactCard from './artifacts/ArtifactCard';
import DepartmentsList from './DepartmentsList';
import DepartmentCard from './DepartmentCard';
import { RESET_SINGLE_DEPARTMENT, SET_SINGLE_DEPARTMENT } from '../../../redux/departments/slices/departmentsSlice';
import { RESET_SINGLE_ARTIFACT } from '../../../redux/departments/slices/artifactsSlice';
import ArtifactsList from './artifacts/ArtifactsList';
import useArtifacts from '../../../redux/departments/hooks/useArtifacts';
import PermissionsStartPage from '../../../components/permissions/PermissionsStartPage';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getArtifactsStorageUUID } from '../../../redux/departments/selectors/artifactsStorageSelector';

export default function DepartmentRoot({
  departments,
  depPaginationOpt,
  onReloadDepartments,
}) {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  const activeDepartment = query.get('department');
  const activeArtifact = query.get('artifact');

  const partitionUuid = useSelector(getDeliveryPartition);
  const artifactsStorageUUID = useSelector(getArtifactsStorageUUID);

  const department = useSelector(getDepartment);
  const departmentUUID = useSelector(getDepartmentUUID);

  const [activeKey, setActiveKey] = useState('1');

  const {
    artifacts,
    paginationOptions: depArtPagOpt,
    onReloadArtifacts,
  } = useArtifacts({
    parentUUID: activeDepartment,
  });

  const {
    artifacts: artifactsWithoutDepartment,
    paginationOptions: artWithoutDepPagOpt,
    onReloadArtifacts: onReloadArtWithoutDep,
  } = useArtifacts({
    parentUUID: artifactsStorageUUID,
  });

  const onChangeParams = (deleteArr = [], setArr = []) => {
    deleteArr?.forEach((el) => {
      query.delete(el);
    });
    setArr?.forEach(([str, value]) => {
      query.set(str, value);
    });
    history.push({
      pathname: location.pathname,
      search: query.toString(),
    });
  };

  const onBackArtCallback = () => {
    onChangeParams(['artifact']);
  };

  const onCreateArtCallback = (uuid) => {
    onChangeParams([], [['artifact', uuid]]);
    onReloadArtifacts();
  };

  const onCreateArtWithoutDepCallback = (uuid) => {
    onChangeParams([], [['artifact', uuid]]);
    onReloadArtWithoutDep();
  };

  const onDeleteArtCallback = () => {
    onChangeParams(['artifact']);
    onReloadArtifacts();
    onReloadArtWithoutDep();
  };

  const onReloadListArtCallback = () => {
    onReloadArtifacts();
    onReloadArtWithoutDep();
  };

  const onBackDepCallback = () => {
    onChangeParams(['department']);
  };

  const onEditDepCallback = (data) => {
    if (data) {
      dispatch({
        type: SET_SINGLE_DEPARTMENT.type,
        payload: data,
      });
    } else if (activeDepartment) {
      dispatch(getSingleDepartment({
        entity_uuid: activeDepartment,
      }));
    }
  };

  const onDeleteDepCallback = () => {
    onChangeParams(['department']);
    onReloadDepartments();
  };

  useEffect(() => {
    if (activeDepartment) {
      dispatch(getSingleDepartment({
        entity_uuid: activeDepartment,
      }));
    } else {
      dispatch(RESET_SINGLE_DEPARTMENT);
    }
  }, [activeDepartment]);

  useEffect(() => {
    if (activeArtifact) {
      dispatch(getSingleArtifact({
        entity_uuid: activeArtifact,
      }));
    } else {
      dispatch(RESET_SINGLE_ARTIFACT);
    }
  }, [activeArtifact]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Отделы
        </Flex>
      )}
    >
      <Flex
        justify="space-between"
        className="w-full"
      >
        <Flex
          vertical
          className="w-1/2"
        >
          <DepartmentsList
            selectedData={department}
            departments={departments}
            paginationOptions={depPaginationOpt}
            onReloadDepartments={onReloadDepartments}
          />
          {activeDepartment && (
            <ArtifactsList
              showUserCreateButton
              artifacts={artifacts}
              department={department}
              departmentUUID={activeDepartment}
              parent={activeDepartment}
              paginationOptions={depArtPagOpt}
              title="Артефакты"
              onCreateArtifactCallback={onCreateArtCallback}
            />
          )}
          <ArtifactsList
            artifacts={artifactsWithoutDepartment}
            parent={artifactsStorageUUID}
            paginationOptions={artWithoutDepPagOpt}
            title="Нет отдела (артефакты)"
            cardClassName="orange-base-card mb-4"
            onCreateArtifactCallback={onCreateArtWithoutDepCallback}
          />
        </Flex>
        <Flex
          vertical
          className="w-1/2 ml-6"
        >
          {activeDepartment && (
            <Flex
              className="mb-6"
              vertical
            >
              <Tabs
                type="card"
                defaultActiveKey="1"
                activeKey={activeKey}
                items={[
                  {
                    key: '1',
                    label: 'Отдел',
                  },
                  {
                    key: '2',
                    label: 'Perms',
                  },
                ]}
                onChange={setActiveKey}
                tabBarStyle={{ marginBottom: 0 }}
              />
              {activeKey === '1' && (
                <DepartmentCard
                  department={department}
                  departmentUUID={departmentUUID}
                  className="!mt-2"
                  onEditDepartmentCallback={onEditDepCallback}
                  onBackDepartmentCallback={onBackDepCallback}
                  onDeleteDepartmentCallback={onDeleteDepCallback}
                />
              )}
              {activeKey === '2' && (
              <PermissionsStartPage
                isModalMode
                entityUUID={departmentUUID}
                partitionType="DELIVERY"
                className="mt-2"
                nameSectionPermissions={`${department?.params?.title} department permissions`}
              />
              )}
            </Flex>
          )}
          {activeArtifact && (
          <ArtifactCard
            onBackArtCallback={onBackArtCallback}
            onUpdateArtCallback={onReloadListArtCallback}
            onDeleteArtCallback={onDeleteArtCallback}
          />
          )}
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

DepartmentRoot.propTypes = {
  storageUUID: string,
  departments: array,
  depPaginationOpt: object,
  onReloadDepartments: func,
};
