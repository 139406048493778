import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { array } from 'prop-types';
import { get, isNil } from 'lodash';
import { Flex, Tag, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiAlertCircleOutline, mdiArrowRightThin } from '@mdi/js';
import humanizeDuration from 'humanize-duration';
import dayjs from 'dayjs';
import UsersVacationsRequestCard from '../../userVacations/UsersVacationsRequestCard';
import useAPI from '../../../../api/useAPI';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import TableVacations from '../../../user/vacationsRequests/TableVacations';
import { getLabelByStatus, maxCountOfVacationDays } from '../../../user/vacationsRequests/utils';
import { daysToMilliseconds, diffDays } from '../../../../utils/dateTimeUtils';
import { getFullNameOrInitials } from '../../../../54origins/utils54origins';
import CopyURL from '../../../../components/commonComponents/CopyURL';
import { hideColumnsVacations } from '../../userVacations/utils';
import { getVacationStatusColor } from '../../../../utils';
import { enumerateDaysBetweenDates } from '../../../../utils/commonUtils';

export default function VacationsForDepartment({
  departmentUUID = '',
}) {
  const { getUserVacationsRequests } = useAPI();

  const rootPartitionUuid = useSelector(getDeliveryPartition);

  const [dataTable, setDataTable] = useState([]);

  const getVacationsRequests = async () => {
    const res = await getUserVacationsRequests(
      departmentUUID,
      {
        params: {
          rangeDates: enumerateDaysBetweenDates(
            dayjs().format('YYYY-MM-DD'),
            dayjs().add(1, 'year').format('YYYY-MM-DD'),
          ),
          status: ['pending', 'pre-agreed', 'approved'],
        },
      },
      [
        'VACATIONS_FOR_DEPARTMENT_REQUEST',
        'VACATIONS_FOR_DEPARTMENT_SUCCESS',
        'VACATIONS_FOR_DEPARTMENT_FAILURE',
      ],
    );

    const vacations = res?.data ?? [];

    setDataTable(vacations);
  };

  const getColumnsCallback = useCallback((defaultColumns) => {
    const data = defaultColumns.reduce(((acc, i) => {
      if (i?.key === 'status') {
        return [
          {
            title: 'Данные',
            dataIndex: ['uinfo'],
            render: (uinfo, row) => {
              const fullName = getFullNameOrInitials({ uinfo }, 'fullname');
              const startDate = get(row, 'params.start_date');
              const endDate = get(row, 'params.end_date');
              const status = get(row, 'params.status');
              const days = diffDays(startDate, endDate);
              return (
                <Flex vertical>
                  <Flex align="center">
                    {days > maxCountOfVacationDays ? (
                      <Tooltip
                        title={`Отпуск больше ${maxCountOfVacationDays} дней!`}
                        color="#DC2626"
                        className="mr-1"
                      >
                        <Icon
                          path={mdiAlertCircleOutline}
                          size={1}
                          color="#DC2626"
                        />
                      </Tooltip>
                    ) : null}
                    {fullName?.length >= 60 ? (
                      <Tooltip
                        style={{ width: 200 }}
                        title={fullName}
                      >
                        {fullName.slice(0, 57)}
                        ...
                      </Tooltip>
                    ) : fullName}
                  </Flex>
                  <Tag
                    color={getVacationStatusColor(status)}
                    className="w-max mt-1"
                  >
                    {getLabelByStatus(status)}
                  </Tag>
                </Flex>
              );
            },
          }, ...acc];
      }

      if (i?.key === 'date') {
        return [
          ...acc,
          {
            className: 'col-date',
            title: 'Период',
            key: 'date',
            render: (cell, row) => {
              const startDate = get(row, 'params.start_date');
              const endDate = get(row, 'params.end_date');
              const status = get(row, 'params.status');
              const days = diffDays(startDate, endDate);
              const diffDate = daysToMilliseconds(days);
              return (
                <Flex
                  vertical
                >
                  <div className={`mr-4 ${days > maxCountOfVacationDays && status !== 'approved' ? 'text-red-600' : 'text-green-600'} font-medium`}>
                    {humanizeDuration(diffDate, { units: ['d'], round: true, language: 'ru' })}
                    {' '}
                  </div>
                  <Flex>
                    {dayjs(startDate).locale('ru').format('DD-MM-YYYY')}
                    <Icon path={mdiArrowRightThin} className="mx-2" size={1} />
                    {dayjs(endDate).locale('ru').format('DD-MM-YYYY')}
                  </Flex>
                </Flex>
              );
            },
          }];
      }
      if (i?.key === 'created') {
        return [
          ...acc,
          {
            title: 'Согласовал',
            dataIndex: ['params', 'agreed'],
            render: (agreed, row) => {
              if (!agreed) {
                return '-';
              }
              return `${get(agreed?.uinfo, 'first_name')} ${get(agreed?.uinfo, 'last_name')}`;
            },
          },
          i];
      }
      return [...acc, i];
    }), []);

    return data;
  }, [JSON.stringify(dataTable)]);

  useEffect(() => {
    if (departmentUUID) {
      getVacationsRequests();
    }
  }, [departmentUUID]);

  return (
    <Flex className="overflow-hidden">
      <TableVacations
        onRow={() => {}}
        id="vacationRequestsForDepartment"
        total={dataTable?.length}
        vacations={dataTable}
        isSorterStatus={false}
        hide={{
          type: true,
        }}
        hidePagination
        getColumnsCallback={getColumnsCallback}
        savePaginationConfigCallback={() => {}}
        currentSortCallback={() => {}}
      />
    </Flex>
  );
}

VacationsForDepartment.propTypes = {
  employees: array,
};
