import {
  Calendar, Col, Flex, Row, Spin,
} from 'antd';
import {
  array, bool, func, string,
} from 'prop-types';
import React from 'react';

import './calendar.scss';

import dayjs from 'dayjs';
import DateSwitcher from '../../../../components/dateSwitcher/DateSwitcher';

import ViewDay from './ViewDay';
import { getHours, localConfig } from '../utils';
import { getBusinessDays } from '../../../../utils/dateTimeUtils';
import UserPersonalStatisticsForMonth from '../../statistics/personal/UserPersonalStatisticsForMonth';
import UserPersonalReportHistory from './UserPersonalReportHistory';

function CalendarDayOff({
  getPrevDayCallback,
  actor,
  dataSource,
  loading,
  startDate,
  changeCurrentDayCallback,
  saveCallback,
  updateDayCallback,
}) {
  const begin = dayjs(startDate).startOf('month');
  const end = dayjs(startDate).endOf('month');

  const totalWorksForMonth = getHours([...dataSource]
    .map(([key, value]) => ({ ...value })));

  const dateFullCellRender = (date) => (
    <ViewDay
      getPrevDayCallback={getPrevDayCallback}
      updateDayCallback={updateDayCallback}
      dataSource={dataSource}
      currentDay={dayjs(date).format('YYYY-MM-DD')}
      defaultDate={startDate}
      saveCallback={saveCallback}
    />
  );

  const onChangeHandler = (date) => {
    changeCurrentDayCallback(date.startOf('month').format('YYYY-MM-DD'));
  };

  const onPanelChange = () => {
    changeCurrentDayCallback(startDate);
  };

  const headerRender = () => (
    <Flex
      align="center"
    >
      <Flex vertical>
        <DateSwitcher
          currentDate={startDate}
          onChangeHandler={onChangeHandler}
        />
        <div style={{
          marginLeft: 18, marginTop: -6, marginBottom: 0, fontSize: 16,
        }}
        >
          Отработанное время за месяц:
          {' '}
          {totalWorksForMonth}
          {' '}
          из
          {' '}
          {getBusinessDays(end, begin) * 8}
        </div>
      </Flex>
      <Flex
        className="ml-3"
        style={{ marginTop: -14 }}
      >
        <UserPersonalStatisticsForMonth
          actor={actor}
        />
        <UserPersonalReportHistory
          currentDate={startDate}
          actor={actor}
        />
      </Flex>
    </Flex>
  );

  return (
    <div className="wrapper_card_calendar">
      <Spin spinning={loading}>
        <Calendar
          locale={localConfig}
          headerRender={headerRender}
          value={dayjs(startDate)}
          onPanelChange={onPanelChange}
          fullCellRender={dateFullCellRender}
        />
      </Spin>
    </div>
  );
}

CalendarDayOff.propTypes = {
  getPrevDayCallback: func,
  changeCurrentDayCallback: func,
  dataSource: array,
  loading: bool,
  startDate: string,
  saveCallback: func,
  currentActor: string,
  updateDayCallback: func,
};

export default CalendarDayOff;
