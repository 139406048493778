import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { get, omit } from 'lodash';
import { getAllDepartments } from '../actions/departmentsActions';
import { getDepartments, getTotalDepartments } from '../selectors/departmentsSelector';
import { getDeliveryPartition } from '../../config/selectors';

const useDepartments = () => {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const departments = useSelector(getDepartments);
  const total = useSelector(getTotalDepartments);

  const [currentPage, setCurrentPage] = useState(1);
  const [config, setConfig] = useState({
    offset: 0,
    limit: 10,
  });

  const initFunc = () => {
    if (partitionUuid) {
      const newConfig = {
        ...config,
      };
      dispatch(getAllDepartments(newConfig, partitionUuid));
    }
  };

  useEffect(() => {
    initFunc();
  }, [partitionUuid, JSON.stringify(config)]);

  return {
    departments,
    paginationOptions: {
      total,
      pageLimit: get(config, 'limit', 10),
      changePageLimit: (newLimit) => {
        setCurrentPage(1);
        setConfig((prev) => ({ ...prev, offset: 0, limit: newLimit }));
      },
      newCurrentPage: currentPage,
      onChangePageCallback: (newPage) => {
        setCurrentPage(newPage);
        setConfig((prev) => ({ ...prev, offset: (newPage - 1) * prev.limit }));
      },
      getPaginationOptions: (newPart) => {
        const valueSearch = get(newPart, 'search', '');

        if (valueSearch) {
          setConfig((prev) => ({
            ...prev,
            ...omit(newPart, ['search']),
            searchData: {
              value: valueSearch,
              ignore_case: true,
              fields: { params: ['title', 'id'] },
            },
          }));
        } else {
          setConfig((prev) => ({ ...omit(prev, 'searchData'), newPart }));
        }
      },
    },
    onReloadDepartments: initFunc,
  };
};

export default useDepartments;
