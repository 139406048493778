/* eslint-disable import/prefer-default-export */
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
  getOrCreateSingle,
} from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import {
  CREATE_ARTIFACT_REQUEST,
  CREATE_ARTIFACT_SUCCESS,
  CREATE_ARTIFACT_FAILURE,
  GET_ARTIFACTS_REQUEST,
  GET_ARTIFACTS_SUCCESS,
  GET_ARTIFACTS_FAILURE,
  GET_SINGLE_ARTIFACT_REQUEST,
  GET_SINGLE_ARTIFACT_SUCCESS,
  GET_SINGLE_ARTIFACT_FAILURE,
  DELETE_SINGLE_ARTIFACT_REQUEST,
  DELETE_SINGLE_ARTIFACT_SUCCESS,
  DELETE_SINGLE_ARTIFACT_FAILURE,
  UPDATE_SINGLE_ARTIFACT_REQUEST,
  UPDATE_SINGLE_ARTIFACT_SUCCESS,
  UPDATE_SINGLE_ARTIFACT_FAILURE,
} from '../slices/artifactsSlice';
import {
  GET_OR_CREATE_ARTIFACTS_STORAGE_REQUEST,
  GET_OR_CREATE_ARTIFACTS_STORAGE_SUCCESS,
  GET_OR_CREATE_ARTIFACTS_STORAGE_FAILURE,
} from '../slices/artifactsStorageSlice';

export const getAllArtifacts = (parent, options, constants) => (dispatch) => dispatch(
  getListAndReadEntities({
    data: {
      entity_type: ['instruction', 'file', 'employee'],
      parent,
      ...options,
    },
    constants: constants || [
      GET_ARTIFACTS_REQUEST,
      GET_ARTIFACTS_SUCCESS,
      GET_ARTIFACTS_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const createArtifact = (data) => (dispatch) => dispatch(
  entityCreate({
    data,
    constants: [
      CREATE_ARTIFACT_REQUEST,
      CREATE_ARTIFACT_SUCCESS,
      CREATE_ARTIFACT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const getSingleArtifact = (options, constants) => (dispatch) => dispatch(
  entityRead({
    data: {
      ...options,
      with_files: true,
    },
    constants: constants || [
      GET_SINGLE_ARTIFACT_REQUEST,
      GET_SINGLE_ARTIFACT_SUCCESS,
      GET_SINGLE_ARTIFACT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const deleteSingleArtifact = (entityUUID, constants) => (dispatch) => dispatch(
  entityDelete({
    data: {
      entity_type: ['instruction', 'file', 'schedule'],
      entity_uuid: entityUUID,
    },
    constants: constants || [
      DELETE_SINGLE_ARTIFACT_REQUEST,
      DELETE_SINGLE_ARTIFACT_SUCCESS,
      DELETE_SINGLE_ARTIFACT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const updateSingleArtifact = (data, constants) => (dispatch) => dispatch(
  entityUpdate({
    data,
    constants: constants || [
      UPDATE_SINGLE_ARTIFACT_REQUEST,
      UPDATE_SINGLE_ARTIFACT_SUCCESS,
      UPDATE_SINGLE_ARTIFACT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const getOrCreateArtifactsStorage = (parent, constants) => (dispatch) => dispatch(
  getOrCreateSingle({
    data: {
      parent,
      entity_type: 'artifactsStorage',
    },
    constants: constants || [
      GET_OR_CREATE_ARTIFACTS_STORAGE_REQUEST,
      GET_OR_CREATE_ARTIFACTS_STORAGE_SUCCESS,
      GET_OR_CREATE_ARTIFACTS_STORAGE_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);
