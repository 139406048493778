import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import useDepartments from '../../../redux/departments/hooks/useDepartments';

import DepartmentRoot from './DepartmentRoot';

import './department.scss';

import { getDeliveryPartition } from '../../../redux/config/selectors';
import { getOrCreateArtifactsStorage } from '../../../redux/departments/actions/artifactsActions';

export default function DepartmentPage() {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const {
    departments,
    paginationOptions,
    onReloadDepartments,
  } = useDepartments();

  useEffect(() => {
    if (partitionUuid) {
      dispatch(getOrCreateArtifactsStorage(partitionUuid));
    }
  }, [partitionUuid]);

  return (
    <Switch>
      <Route
        path="/departments"
        render={() => (
          <DepartmentRoot
            departments={departments}
            depPaginationOpt={paginationOptions}
            onReloadDepartments={onReloadDepartments}
          />
        )}
      />
    </Switch>
  );
}
