import React, { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Alert, Button } from 'antd';
import { capitalize } from 'lodash';
import Icon from '@mdi/react';
import { mdiArrowLeftThick } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import TimeDashboard from '../../user/TimeDashboard';
import useAPI from '../../../api/useAPI';
import {
  getMonthlyStorageFetching,
  getUserRootInfo,
  getVacationStorageFetching,
} from '../../../redux/storages/selectors';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { getNameForActor } from '../../../54origins/utils54origins';
import StoragesConstants from '../../../redux/storages/constants';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import {
  getIsFetchingMasqueradeUser,
  getMasqueradeUserActorUUID,
  getMasqueradeUserInfo,
  getMasqueradeUserUUID,
} from '../../../redux/masquerading/masqueradingSelector';

export default function UserDashboard() {
  const dispatch = useDispatch();

  const history = useHistory();
  //  const { actor } = useParams();

  const partitionUUID = useSelector(getDeliveryPartition);

  const masqueradeUserActorUUID = useSelector(getMasqueradeUserActorUUID);
  const masqueradeUserUUID = useSelector(getMasqueradeUserUUID);
  const masqueradeUser = useSelector(getMasqueradeUserInfo);
  const isFetchingUser = useSelector(getIsFetchingMasqueradeUser);

  const urlParams = new URLSearchParams(window.location.search);
  const actor = urlParams.get('actor');

  const {
    getMasqueradeUser,
    getVacationStorage,
    getMonthlyReportsStorage,
  } = useAPI();

  const customTitle = useMemo(() => (
    <div className="w-full">
      <Alert
        message={(
          <div className="flex items-center w-full">
            <Button
              className="flex items-center"
              onClick={() => {
                dispatch({ type: StoragesConstants.RESET_VACATION_STORAGE });
                dispatch({ type: StoragesConstants.RESET_MONTHLY_REPORTS_STORAGE });
                history.goBack();
              }}
            >
              <Icon path={mdiArrowLeftThick} className="mr-2" size={0.8} />
              {capitalize('Вернуться')}
            </Button>
            <h5 className="ml-3 !my-0 pb-0">
              {capitalize('Вы находитесь в reports дашборд')}
              <span className="ml-1 text-blue-600">{capitalize(getNameForActor(masqueradeUser))}</span>
            </h5>
          </div>
      )}
        type="warning"
        className="w-full"
      />
    </div>

  ), [JSON.stringify(masqueradeUser)]);

  useEffect(() => {
    if (!isFetchingUser
      && masqueradeUserActorUUID
      && masqueradeUserUUID
    ) {
      getVacationStorage(masqueradeUserUUID, masqueradeUserActorUUID);
      getMonthlyReportsStorage(masqueradeUserUUID, masqueradeUserActorUUID);
    }
  }, [masqueradeUserUUID, masqueradeUserActorUUID, isFetchingUser]);

  useEffect(() => {
    if (actor && partitionUUID) {
      getMasqueradeUser(actor);
    }
    return () => {
      dispatch({ type: StoragesConstants.RESET_VACATION_STORAGE });
      dispatch({ type: StoragesConstants.RESET_MONTHLY_REPORTS_STORAGE });
    };
  }, [actor, partitionUUID]);

  return masqueradeUserActorUUID === actor && masqueradeUserUUID ? (
    <TimeDashboard
      title={customTitle}
      actorUUID={masqueradeUserActorUUID}
    />
  ) : null;
}
