import React, {
  useEffect, useMemo, useState,
} from 'react';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';
import {
  Button, Card, Col, Divider, Space, Spin,
} from 'antd';
import {
  capitalize, get, isUndefined, omit,
} from 'lodash';
import Icon from '@mdi/react';
import { mdiFileExportOutline } from '@mdi/js';
import { useSelector } from 'react-redux';
import { allTagsForStatisticsTable } from './constants';
import DateSwitcher from '../../../components/dateSwitcher/DateSwitcher';
import UsersStatisticsCard from './UsersStatisticsCard';
import PageWrapper from '../../../components/PageWrapper';
import { getBusinessDays } from '../../../utils/dateTimeUtils';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import TagsSettingList from '../../../components/tags/tagsFilter/TagsSettingList';
import { capitalizeFirstChar } from '../../../utils';
import useAPI from '../../../api/useAPI';
import { getBanGroupUUID, getFetchingGroups } from '../../../redux/groups/selectors';
import { getFetchingReports54Actors, getReports54Actors, getReports54ActorsTotal } from '../../../redux/reports54Actors/selectors';
import { isRootOrAdmin } from '../../../redux/profile/selectors';
import CustomTablePagination from '../../../components/commonComponents/customTablePagination/CustomTablePagination';

const defaultSelectedTags = [{
  key: 'internal',
  value: 'internal',
  label: capitalizeFirstChar('internal'),
  className: 'internal-tag',
  ruleOfTag: 'included',
}, {
  key: 'banned',
  value: 'banned',
  label: capitalizeFirstChar('banned'),
  className: 'banned-tag',
  ruleOfTag: 'excluded',
}];

const sentReportStatus = ['auto', 'submitted'];

export default function UsersStatistics() {
  const {
    getDeliveryUsers,
    getListOfMonthlyReports,
  } = useAPI();

  const rootPartitionUuid = useSelector(getDeliveryPartition);

  const banGroupUUID = useSelector(getBanGroupUUID);
  const isFetchingGroups = useSelector(getFetchingGroups);

  const actors = useSelector(getReports54Actors);
  const isFetchingActors = useSelector(getFetchingReports54Actors);
  const totalActors = useSelector(getReports54ActorsTotal);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);

  const [currentMonth, setCurrentMonth] = useState(dayjs().subtract(1, 'month').format('YYYY-MM'));

  const [dataTable, setDataTable] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const [isFetching, setFetching] = useState(true);

  const [withReports, setWithReports] = useState('all');

  const [config, setConfig] = useState({
    actor_type: ['classic_user', 'user'],
    uinfo: {
      groups__not: [banGroupUUID],
      internal_user: true,
    },
    depth: 0,
    order: 'desc',
    order_by: 'created',
  });

  const begin = dayjs(currentMonth).startOf('month');
  const end = dayjs(currentMonth).endOf('month');

  const withPagination = useMemo(() => withReports === 'all'
  && isUserRootOrAdmin, [withReports, isUserRootOrAdmin]);

  const getActorsList = async (options = {}) => {
    await getDeliveryUsers({ ...config, ...options });
  };

  const onChangeDate = (date) => {
    setCurrentMonth(dayjs(date).format('YYYY-MM'));
  };

  const getReports = async (uuids = [], options = {}) => {
    setFetching(true);

    const reportsConfig = {
      params: { date: currentMonth },
      ...options,
    };

    if (uuids?.length > 0) {
      reportsConfig.actor = uuids;
    }

    const res = await getListOfMonthlyReports(
      rootPartitionUuid,
      reportsConfig,
    );

    const reports = res?.data
      ?.filter((report) => sentReportStatus.includes(report?.params?.status));

    setFetching(false);
    return reports ?? [];
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
    setConfig((prev) => ({
      ...prev,
      offset: (page - 1) * pageSize,
      limit: pageSize,
    }));
  };

  const onShowSizeChange = (current, size) => {
    setCurrentPage(1);
    setPageSize(size);
    setConfig((prev) => ({
      ...prev,
      offset: 0,
      limit: size,
    }));
  };

  const onSearch = (value) => {
    setCurrentPage(1);
    setConfig((prev) => ({
      ...prev,
      limit: pageSize,
      offset: 0,
      search_data: {
        fields: {
          uinfo: {
            first_name: 'first_name',
            last_name: 'last_name',
          },
        },
        value,
      },
    }));
  };

  const onSelectTags = (tags) => {
    const newConfig = {
      ...config,
      uinfo: {},
      limit: config?.limit ?? 100,
      offset: 0,
      order: 'desc',
      order_by: 'created',
    };

    let newWithReports = 'all';

    tags.forEach((tag) => {
      if (tag.key === 'internal' && tag.ruleOfTag === 'excluded') {
        newConfig.uinfo.internal_user = false;
      }
      if (tag.key === 'internal' && tag.ruleOfTag === 'included') {
        newConfig.uinfo.internal_user = true;
      }
      if (tag.key === 'banned' && tag.ruleOfTag === 'excluded') {
        newConfig.uinfo.groups__not = [banGroupUUID];
      }
      if (tag.key === 'banned' && tag.ruleOfTag === 'included') {
        newConfig.uinfo.groups = [banGroupUUID];
      }
      if (tag.key === 'report' && tag.ruleOfTag === 'excluded') {
        newWithReports = 'excluded';
      }
      if (tag.key === 'report' && tag.ruleOfTag === 'included') {
        newWithReports = 'included';
      }
    });

    setConfig(newConfig);
    setWithReports(newWithReports);
  };

  const dataForDownload = useMemo(() => {
    const data = new Map([]);
    dataTable.forEach(({ report, actor }) => {
      const columns = {
        Имя: `${report?.uinfo?.first_name ?? actor?.uinfo?.first_name} ${report?.uinfo?.last_name ?? actor?.uinfo?.last_name}`,
        'Проектных часов': get(report, 'params.statistics.totalWorks') || 0,
        Бенчи: get(report, 'params.statistics.totalBenches') || 0,
        'Нерабочих часов': get(report, 'params.statistics.totalDayOff') || 0,
        'Клиентский пр.': get(report, 'params.statistics.clientWork') || 0,
        'Внутренний пр.': get(report, 'params.statistics.internalWork') || 0,
        Отгул: get(report, 'params.statistics.dayoff') || 0,
        Больничный: get(report, 'params.statistics.sickday') || 0,
        Отпуск: get(report, 'params.statistics.vacation') || 0,
        Создан: get(report, 'created') || 'Отчета нет',
      };
      data.set(actor?.actor, columns);
    });
    return [...data.values()];
  }, [JSON.stringify(dataTable)]);

  const initFunc = async () => {
    let reports = [];
    let data = [];
    let users = [];
    switch (withReports) {
      case 'all':
        reports = [];
        if (actors.length > 0) {
          reports = await getReports(actors?.map((el) => el?.actor));
        }
        data = actors.map((actor) => {
          const userReport = reports.find((report) => report?.params?.actorUuid === actor?.actor);
          if (userReport) {
            return {
              report: userReport,
              actor,
              hasReport: true,
            };
          }
          return { actor, hasReport: false };
        });

        setDataTable(data);
        break;
      case 'included':
        reports = await getReports([], omit(config, ['limit', 'offset']));

        data = reports.map((report) => ({
          report,
          actor: report,
          hasReport: true,
        }));

        setDataTable(data);
        break;
      case 'excluded':
        users = await getDeliveryUsers(omit(config, ['limit', 'offset']), [
          'FETCH_ACTORS_REQUEST',
          'FETCH_ACTORS_SUCCESS',
          'FETCH_ACTORS_FAILURE',
        ]);
        reports = await getReports(users?.data?.map((el) => el?.actor));
        data = users?.data
          ?.filter((user) => reports.every((report) => report?.params?.actorUuid !== user?.actor))
          ?.map((actor) => ({ actor, hasReport: false }));

        setDataTable(data);
        break;
      default: break;
    }
  };

  useEffect(() => {
    if (!isFetchingGroups && rootPartitionUuid) {
      if (config?.uinfo?.groups__not?.length
        && isUndefined(config?.uinfo?.groups__not?.[0])
        && isUserRootOrAdmin) {
        setCurrentPage(1);
        getActorsList({
          limit: pageSize || 100,
          offset: 0,
          depth: 0,
          uinfo: {
            groups__not: [banGroupUUID],
            internal_user: true,
          },
        });
      } else {
        getActorsList();
      }
    }
  }, [isFetchingGroups,
    banGroupUUID,
    rootPartitionUuid,
    JSON.stringify(config),
  ]);

  useEffect(() => {
    if (currentMonth && rootPartitionUuid) {
      initFunc();
    }
  }, [currentMonth,
    rootPartitionUuid,
    JSON.stringify(actors),
    withReports,
  ]);

  return (
    <PageWrapper
      title={capitalize('Отчетная статистика')}
      needWrapperClassName={false}
    >
      <Card
        title={(
          <Space split={<Divider type="vertical" />} align="center">
            <Col style={{ marginTop: 6, marginBottom: -6 }}>
              <DateSwitcher
                currentDate={currentMonth}
                onChangeHandler={onChangeDate}
              />
              <div style={{
                marginLeft: 18, color: 'grey', marginTop: -10, marginBottom: 10,
              }}
              >
                Рабочих часов в месяце:
                {getBusinessDays(end, begin) * 8}
              </div>
            </Col>
            <CSVLink data={dataForDownload} filename={`отчет за ${currentMonth}.csv`}>
              <Button type="primary" className="flex items-center gap-2" style={{ marginTop: -6 }}>
                <Icon path={mdiFileExportOutline} size={1} />
                Экспорт в CSV
              </Button>
            </CSVLink>
          </Space>
  )}
        className="card-h-full"
      >
        <Col style={{ marginBottom: 10 }}>
          <TagsSettingList
            allTags={allTagsForStatisticsTable({ showBannedTag: isUserRootOrAdmin })}
            defaultSelectedTags={defaultSelectedTags}
            size="small"
            onSelectTags={onSelectTags}
          />
        </Col>
        <div className="relative h-full w-full flex overflow-hidden box-border">
          <div className="relative h-full flex  w-full flex-col box-border overflow-hidden pl-2">
            <Spin spinning={isFetchingActors || isFetching}>
              <UsersStatisticsCard
                dataTable={dataTable}
                currentMonth={currentMonth}
                setConfig={setConfig}
                config={config}
                total={totalActors}
                withPagination={withPagination}
                onSearch={onSearch}
              />
            </Spin>

            {withPagination ? (
              <CustomTablePagination
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={['25', '50', '100', '200']}
                onChangePage={onChangePagination}
                currentPage={currentPage}
                total={totalActors}
                pageLimit={pageSize}
              />
            ) : null}
          </div>
        </div>

      </Card>
    </PageWrapper>
  );
}
