import { useDispatch, useSelector } from 'react-redux';

import { head, omit } from 'lodash';
import { SERVICE_PARTITION_NAME, authServiceURL, currentServiceUrl } from './app_config';
import {
  entityCreate,
  entityDelete,
  entityRead,
  entityUpdate,
  getListAndReadEntities,
  getListOfEntities,
  getOrCreateSingle,
} from './actions/entity';
import axiosRequest from './api';

import AuthConstants from '../redux/auth/constants';
import ConfigConstants from '../redux/config/constants';
import { getDeliveryPartition } from '../redux/config/selectors';
import ProfileConstants from '../redux/profile/constants';
import PublicUIConstants from '../redux/publicInterfaces/constants/types';
import UserVacationConstants from '../redux/userVacation/constants';
import VacationConstants from '../redux/vacations/constants';
import rootEntitiesConstants from '../redux/rootEntities/constants';
import UserDayOffConstants from '../redux/userDayOff/constants';
import { getMD5HashFromString } from '../utils';
import ReportConstants from '../redux/reports/constants';
import UserHolidaysConstants from '../redux/userHolidays/constants';
import HolidaysConstants from '../redux/holidays/constants';
import { getProfileInfo, getProfileUUID, isRootOrAdmin } from '../redux/profile/selectors';
import StoragesConstants from '../redux/storages/constants';
import Reports54ActorsConstants from '../redux/reports54Actors/constants';
import { GET_MASQUERADE_USER_FAILURE, GET_MASQUERADE_USER_REQUEST, GET_MASQUERADE_USER_SUCCESS } from '../redux/masquerading/masqueradingSlice';

const useAPI = () => {
  const dispatch = useDispatch();

  const myProfileInfo = useSelector(getProfileInfo);
  const isAdminOrRoot = useSelector(isRootOrAdmin);
  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const actor = useSelector(getProfileUUID);

  function createEntityPermissions({
    data,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/create',
          [
            'CREATE_ENTITY_PERM_REQUEST',
            'CREATE_ENTITY_PERM_SUCCESS',
            'CREATE_ENTITY_PERM_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  function deleteEntityPerm({
    data,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/delete',
          [
            'DELETE_ENTITY_PERMS_REQUEST',
            'DELETE_ENTITY_PERMS_SUCCESS',
            'DELETE_ENTITY_PERMS_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  const checkEntityPerm = ({
    entity_uuid,
    perm = [],
    returnPermsList,
  }) => {
    const data = {
      entity_uuid,
      perm,
    };

    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          'utility/eperm/check',
          [
            'CHECK_ENTITY_PERM_REQUEST',
            'CHECK_ENTITY_PERM_SUCCESS',
            'CHECK_ENTITY_PERM_FAILURE',
          ],
          data,
          {
            partition: 'DELIVERY',
            onSuccess: (response) => {
              const perms = omit(head(response), ['entity_uuid']);

              resolve(returnPermsList ? perms : perm.every((item) => perms[item] === true));
            },
            onFailure: reject,
          },
        ),
      );
    });
  };

  function getPermissionsOfEntity({
    data,
    constants,
  }) {
    return new Promise((onSuccess, onFailure) => {
      dispatch(
        axiosRequest.post(
          'eperm/get',
          constants || [
            'GET_ENTITY_PERMS_REQUEST',
            'GET_ENTITY_PERMS_SUCCESS',
            'GET_ENTITY_PERMS_FAILURE',
          ],
          data,
          {
            partition: SERVICE_PARTITION_NAME,
            onSuccess,
            onFailure,
          },
        ),
      );
    });
  }

  const requestGetPublicInterfaces = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'public_interfaces/bulk',
        [
          ConfigConstants.GET_PUBLIC_INTERFACES_REQUEST,
          ConfigConstants.GET_PUBLIC_INTERFACES_SUCCESS,
          ConfigConstants.GET_PUBLIC_INTERFACES_FAILURE,
        ],
        { service_domain: `${currentServiceUrl}/` },
        {
          service_domain: authServiceURL,
          service: 'auth',
        },
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });
  const requestGetPartitions = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'partition/get',
        [
          ConfigConstants.GET_PARTITIONS_REQUEST,
          ConfigConstants.GET_PARTITIONS_SUCCESS,
          ConfigConstants.GET_PARTITIONS_FAILURE,
        ],
        {},
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const requestAuthorization = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        'authorization/',
        [
          AuthConstants.GET_LOGIN_TEMPLATE_REQUEST,
          AuthConstants.GET_LOGIN_TEMPLATE_SUCCESS,
          AuthConstants.GET_LOGIN_TEMPLATE_FAILURE,
        ],
        {
          withoutSessionToken: true,
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const requestGetMyPermissions = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'permactions/actor/me',
        [
          ProfileConstants.GET_MY_PERMISSIONS_REQUEST,
          ProfileConstants.GET_MY_PERMISSIONS_SUCCESS,
          ProfileConstants.GET_MY_PERMISSIONS_FAILURE,
        ],
        {},
        {
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  const requestGetOpenInterfaces = () => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.get(
        'services_info/',
        [
          PublicUIConstants.GET_ALL_OPEN_UI_REQUEST,
          PublicUIConstants.GET_ALL_OPEN_UI_SUCCESS,
          PublicUIConstants.GET_ALL_OPEN_UI_FAILURE,
        ],
        {
          service: 'auth',
          onSuccess: resolve,
          onFailure: reject,
          onCancel: reject,
        },
      ),
    );
  });

  // const getRootEntities = () => dispatch(
  //   getListAndReadEntities({
  //     data: {
  //       parent: rootPartitionUuid,
  //     },
  //     constants: [
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_REQUEST,
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_SUCCESS,
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_FAILURE,
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }),
  // );

  const getDefaultDepartment = async () => {
    if (isAdminOrRoot) {
      return dispatch(
        getOrCreateSingle({
          data: {
            entity_type: 'defaultDepartment',
            parent: rootPartitionUuid,
            key: getMD5HashFromString(`defaultDepartment${rootPartitionUuid}`),
          },
          constants: [
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_REQUEST',
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_SUCCESS',
            'GET_OR_CREATE_DEFAULT_DEPARTMENT_FAILURE',
          ],
          partition: SERVICE_PARTITION_NAME,
          doNotCheckError: true,
        }),
      );
    }
    const res = await dispatch(
      getListOfEntities({
        data: {
          entity_type: 'defaultDepartment',
          parent: rootPartitionUuid,
          key: getMD5HashFromString(`defaultDepartment${rootPartitionUuid}`),
        },
        constants: [
          'LIST_DEFAULT_DEPARTMENT_REQUEST',
          'LIST_DEFAULT_DEPARTMENT_SUCCESS',
          'LIST_DEFAULT_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
        doNotCheckError: true,
      }),
    );

    return {
      uuid: head(res?.entity_uuids),
    };
  };

  const getRootUser = (actorUUID = '', parent = '') => dispatch(
    getOrCreateSingle({
      data: {
        parent: parent || rootPartitionUuid,
        entity_type: 'user',
        key: getMD5HashFromString(`user${rootPartitionUuid}${actorUUID || myProfileInfo?.uuid}`),
      },
      constants: [
        StoragesConstants.GET_ROOT_USER_REQUEST,
        StoragesConstants.GET_ROOT_USER_SUCCESS,
        StoragesConstants.GET_ROOT_USER_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const readRootUser = () => dispatch(
    getListAndReadEntities({
      data: {
        actor,
        depth: 0,
        entity_type: 'user',
        parent: rootPartitionUuid,
      },
      constants: [
        'READ_ROOT_USER_REQUEST',
        'READ_ROOT_USER_SUCCESS',
        'READ_ROOT_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const updateUserEntity = (uuid, data, constants) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'user',
        entity_uuid: uuid,
        ...data,
      },
      constants: constants || [
        'UPDATE_USER_REQUEST',
        'UPDATE_USER_SUCCESS',
        'UPDATE_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getMasqueradeUser = (actorUUID = '') => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUUID,
        parent: rootPartitionUuid,
        entity_type: 'user',
        key: getMD5HashFromString(`user${rootPartitionUuid}${actorUUID}`),
      },
      constants: [
        GET_MASQUERADE_USER_REQUEST,
        GET_MASQUERADE_USER_SUCCESS,
        GET_MASQUERADE_USER_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getDeliveryUsers = (options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        parent: rootPartitionUuid,
        entity_type: 'user',
        ...options,
      },
      constants: constants || [
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_REQUEST,
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_SUCCESS,
        Reports54ActorsConstants.FETCH_REPORTS_54_ACTORS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getVacationStorage = (entityUUID, actorUuid, constants) => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUuid,
        parent: entityUUID,
        entity_type: 'vacationStorage',
      },
      constants: constants || [
        StoragesConstants.GET_VACATION_STORAGE_REQUEST,
        StoragesConstants.GET_VACATION_STORAGE_SUCCESS,
        StoragesConstants.GET_VACATION_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getMonthlyReportsStorage = (entityUUID, actorUuid) => dispatch(
    getOrCreateSingle({
      data: {
        actor: actorUuid,
        parent: entityUUID,
        entity_type: 'monthlyReportsStorage',
      },
      constants: [
        StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_REQUEST,
        StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_SUCCESS,
        StoragesConstants.GET_MONTHLY_REPORTS_STORAGE_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  // const getRootEntitiesSignal = () => dispatch(
  //   getOrCreateSingle({
  //     data: {
  //       parent: rootPartitionUuid,
  //       entity_type: 'instructionStorage',
  //     },
  //     constants: [
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_SIGNAL_REQUEST,
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_SIGNAL_SUCCESS,
  //       rootEntitiesConstants.GET_ROOT_ENTITIES_SIGNAL_FAILURE,
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //     doNotCheckError: true,
  //   }),
  // );

  // USER TIMELINE STATUSES //
  // const requestGetUserTimeline = (entityUUID, options = {}) => dispatch(
  //   getListAndReadEntities({
  //     data: {
  //       entity_type: 'timelineStatus',
  //       parent: entityUUID,
  //       ...options,
  //     },
  //     constants: [
  //       UserTimelineProjectConstants.GET_USER_TIMELINES_REQUEST,
  //       UserTimelineProjectConstants.GET_USER_TIMELINES_SUCCESS,
  //       UserTimelineProjectConstants.GET_USER_TIMELINES_FAILURE,
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }),
  // );

  // const requestCreateUserTimelineStatus = (entityUUID, data) => dispatch(
  //   entityCreate({
  //     data: {
  //       entity_type: 'timelineStatus',
  //       parent: entityUUID,
  //       ...data,
  //     },
  //     constants: [
  //       UserTimelineProjectConstants.CREATE_USER_TIMELINE_REQUEST,
  //       UserTimelineProjectConstants.CREATE_USER_TIMELINE_SUCCESS,
  //       UserTimelineProjectConstants.CREATE_USER_TIMELINE_FAILURE,
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }),
  // );

  // const requestUpdateUserTimelineStatus = (affixUUID, params) => dispatch(
  //   entityUpdate({
  //     data: {
  //       entity_type: 'timelineStatus',
  //       entity_uuid: affixUUID,
  //       params,
  //     },
  //     constants: [
  //       UserTimelineProjectConstants.UPDATE_USER_TIMELINE_REQUEST,
  //       UserTimelineProjectConstants.UPDATE_USER_TIMELINE_SUCCESS,
  //       UserTimelineProjectConstants.UPDATE_USER_TIMELINE_FAILURE,
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }),
  // );

  const updateDeliveryUser = (data) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'user',
        ...data,
      },
      constants: [
        'UPDATE_DELIVERY_USER_REQUEST',
        'UPDATE_DELIVERY_USER_SUCCESS',
        'UPDATE_DELIVERY_USER_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // USER DAY OFF //
  const requestGetYearDayOff = (entityUUID, date, constants) => {
    const config = {
      data: {
        entity_type: 'year',
        parent: entityUUID,
        params: {
          date,
        },
        key: getMD5HashFromString(`${entityUUID}${date}`),
      },
      partition: SERVICE_PARTITION_NAME,
      constants: constants || [
        UserDayOffConstants.GET_YEAR_DAY_OFF_REQUEST,
        UserDayOffConstants.GET_YEAR_DAY_OFF_SUCCESS,
        UserDayOffConstants.GET_YEAR_DAY_OFF_FAILURE,
      ],
    };

    return dispatch(getOrCreateSingle(config));
  };

  const requestCreateUserDayOff = (entityUUID, data) => dispatch(
    entityCreate({
      data: {
        entity_type: 'dayOff',
        parent: entityUUID,
        ...data,
      },
      constants: [
        UserDayOffConstants.DELETE_USER_DAY_OFF_REQUEST,
        UserDayOffConstants.DELETE_USER_DAY_OFF_SUCCESS,
        UserDayOffConstants.DELETE_USER_DAY_OFF_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestUpdateUserDayOff = (affixUUID, data) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'dayOff',
        entity_uuid: affixUUID,
        ...data,
      },
      constants: [
        UserDayOffConstants.UPDATE_USER_DAY_OFF_REQUEST,
        UserDayOffConstants.UPDATE_USER_DAY_OFF_SUCCESS,
        UserDayOffConstants.UPDATE_USER_DAY_OFF_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getUserDayOffsRequest = (entityUUID, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'dayOff',
        parent: entityUUID,
        ...options || {},
      },
      constants: constants || [
        UserDayOffConstants.GET_USER_DAY_OFF_REQUEST,
        UserDayOffConstants.GET_USER_DAY_OFF_SUCCESS,
        UserDayOffConstants.GET_USER_DAY_OFF_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestDeleteDayOff = (affixUUID) => dispatch(
    entityDelete({
      data: { entity_type: 'dayOff', entity_uuid: affixUUID },
      constants: [
        UserVacationConstants.DELETE_USER_VACATION_REQUEST,
        UserVacationConstants.DELETE_USER_VACATION_SUCCESS,
        UserVacationConstants.DELETE_USER_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // USER VACATIONS //
  const getListOfVacations = (parent, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'vacation',
        parent,
        depth: 0,
        ...options,
      },
      constants: constants || [
        'GET_LIST_OF_VACATIONS_REQUEST',
        'GET_LIST_OF_VACATIONS_SUCCESS',
        'GET_LIST_OF_VACATIONS_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getAllUserVacationsRequests = (entityUUID, options = {}, constants) => dispatch(
    getListAndReadEntities({
      data: {
        // entity_type: 'vacationRequest',
        parent: entityUUID,
        ...options,
      },
      constants: constants || [
        UserVacationConstants.GET_ALL_USER_VACATIONS_REQUEST,
        UserVacationConstants.GET_ALL_USER_VACATIONS_SUCCESS,
        UserVacationConstants.GET_ALL_USER_VACATIONS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );
  const getUserVacationsRequests = (entityUUID, options = {}, constants) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'vacation',
        parent: entityUUID,
        depth: 0,
        ...options,
      },
      constants: constants || [
        UserVacationConstants.GET_USER_VACATIONS_REQUEST,
        UserVacationConstants.GET_USER_VACATIONS_SUCCESS,
        UserVacationConstants.GET_USER_VACATIONS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestCreateUserVacation = (entityUUID, data) => dispatch(
    entityCreate({
      data: {
        entity_type: 'vacation',
        parent: entityUUID,
        ...data,
      },
      constants: [
        UserVacationConstants.CREATE_USER_VACATION_REQUEST,
        UserVacationConstants.CREATE_USER_VACATION_SUCCESS,
        UserVacationConstants.CREATE_USER_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestUpdateUserVacation = (entityUUID, params) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'vacation',
        entity_uuid: entityUUID,
        params,
      },
      constants: [
        UserVacationConstants.UPDATE_USER_VACATION_REQUEST,
        UserVacationConstants.UPDATE_USER_VACATION_SUCCESS,
        UserVacationConstants.UPDATE_USER_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestDeleteUserVacation = (entityUUID) => dispatch(
    entityDelete({
      data: { entity_type: 'vacation', entity_uuid: entityUUID },
      constants: [
        UserVacationConstants.DELETE_USER_VACATION_REQUEST,
        UserVacationConstants.DELETE_USER_VACATION_SUCCESS,
        UserVacationConstants.DELETE_USER_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // USER HOLIDAYS //
  const getUserHolidaysRequests = (entityUUID, options = {}) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'holidays',
        parent: entityUUID,
        depth: 0,
        ...options,
      },
      constants: [
        UserHolidaysConstants.GET_USER_HOLIDAYS_REQUEST,
        UserHolidaysConstants.GET_USER_HOLIDAYS_SUCCESS,
        UserHolidaysConstants.GET_USER_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestCreateUserHolidays = (entityUUID, data) => dispatch(
    entityCreate({
      data: {
        entity_type: 'holidays',
        parent: entityUUID,
        ...data,
      },
      constants: [
        UserHolidaysConstants.CREATE_USER_HOLIDAYS_REQUEST,
        UserHolidaysConstants.CREATE_USER_HOLIDAYS_SUCCESS,
        UserHolidaysConstants.CREATE_USER_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestUpdateUserHolidays = (affixUUID, params) => dispatch(
    entityUpdate({
      data: {
        entity_type: 'holidays',
        entity_uuid: affixUUID,
        params,
      },
      constants: [
        UserHolidaysConstants.UPDATE_USER_HOLIDAYS_REQUEST,
        UserHolidaysConstants.UPDATE_USER_HOLIDAYS_SUCCESS,
        UserHolidaysConstants.UPDATE_USER_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const requestDeleteUserHolidays = (affixUUID) => dispatch(
    entityDelete({
      data: { entity_type: 'holidays', entity_uuid: affixUUID },
      constants: [
        UserHolidaysConstants.DELETE_USER_HOLIDAYS_REQUEST,
        UserHolidaysConstants.DELETE_USER_HOLIDAYS_SUCCESS,
        UserHolidaysConstants.DELETE_USER_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // VACATIONS //
  const createAgreedVacation = (entityUUID, options = {}) => dispatch(
    entityCreate({
      data: {
        entity_type: 'vacation',
        parent: entityUUID,
        ...options,
      },
      constants: [
        VacationConstants.CREATE_VACATION_REQUEST,
        VacationConstants.CREATE_VACATION_SUCCESS,
        VacationConstants.CREATE_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getAgreedVacations = (entityUUID, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'vacation',
        parent: entityUUID,
        depth: 0,
        ...options || {},
      },
      constants: constants || [
        VacationConstants.GET_VACATIONS_REQUEST,
        VacationConstants.GET_VACATIONS_SUCCESS,
        VacationConstants.GET_VACATIONS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getVacation = (entityUUID, options, constants) => dispatch(
    entityRead({
      data: {
        entity_type: 'vacation',
        parent: entityUUID,
        depth: 0,
        ...options || {},
      },
      constants: constants || [
        VacationConstants.GET_VACATION_REQUEST,
        VacationConstants.GET_VACATION_SUCCESS,
        VacationConstants.GET_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getRequestVacation = (entityUUID, options, constants) => dispatch(
    entityRead({
      data: {
        entity_type: 'vacationRequest',
        parent: entityUUID,
        depth: 0,
        ...options || {},
      },
      constants: constants || [
        VacationConstants.GET_REQ_VACATION_REQUEST,
        VacationConstants.GET_REQ_VACATION_SUCCESS,
        VacationConstants.GET_REQ_VACATION_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );
  // HOLIDAYS //
  const createAgreedHolidays = (entityUUID, options = {}) => dispatch(
    entityCreate({
      data: {
        entity_type: 'holidays',
        parent: entityUUID,
        ...options,
      },
      constants: [
        HolidaysConstants.CREATE_HOLIDAYS_REQUEST,
        HolidaysConstants.CREATE_HOLIDAYS_SUCCESS,
        HolidaysConstants.CREATE_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getAgreedHolidays = (entityUUID, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'holidays',
        parent: entityUUID,
        depth: 0,
        ...options || {},
      },
      constants: constants || [
        HolidaysConstants.GET_HOLIDAYS_REQUEST,
        HolidaysConstants.GET_HOLIDAYS_SUCCESS,
        HolidaysConstants.GET_HOLIDAYS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // Increment
  const incrementAndGetCount = (params, partition = 'SALES') => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'utility/entity/increment',
        ['', '', ''],
        params,
        {
          partition,
          onSuccess: resolve,
          onFailure: reject,
        },
      ),
    );
  });

  // REPORTS
  const requestGetYearReports = (entityUUID, date, constants) => {
    const config = {
      data: {
        entity_type: 'year',
        parent: entityUUID,
        params: {
          date,
        },
        key: getMD5HashFromString(`${entityUUID}${date}`),
      },
      partition: SERVICE_PARTITION_NAME,
      constants: constants || [
        ReportConstants.GET_YEAR_REPORT_REQUEST,
        ReportConstants.GET_YEAR_REPORT_SUCCESS,
        ReportConstants.GET_YEAR_REPORT_FAILURE,
      ],
    };

    return dispatch(getOrCreateSingle(config));
  };

  const createReport = (entityUUID, options = {}) => dispatch(
    entityCreate({
      data: {
        entity_type: 'report',
        parent: entityUUID,
        ...options,
      },
      constants: [
        ReportConstants.CREATE_REPORT_REQUEST,
        ReportConstants.CREATE_REPORT_SUCCESS,
        ReportConstants.CREATE_REPORT_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const createReportWithFiles = (options = {}) => dispatch(
    entityCreate({
      data: options,
      constants: [
        ReportConstants.CREATE_REPORT_REQUEST,
        ReportConstants.CREATE_REPORT_SUCCESS,
        ReportConstants.CREATE_REPORT_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getMonthReports = (entityUUID, options = {}) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'report',
        parent: entityUUID,
        depth: 0,
        with_files: false,
        ...options,
      },
      constants: [
        ReportConstants.GET_REPORTS_REQUEST,
        ReportConstants.GET_REPORTS_SUCCESS,
        ReportConstants.GET_REPORTS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getLastMonthReports = (entityUUID, options = {}) => dispatch(
    getListAndReadEntities({
      data: {
        entity_type: 'report',
        parent: entityUUID,
        depth: 0,
        with_files: false,
        ...options,
      },
      constants: [
        ReportConstants.GET_LAST_MONTH_REPORTS_REQUEST,
        ReportConstants.GET_LAST_MONTH_REPORTS_SUCCESS,
        ReportConstants.GET_LAST_MONTH_REPORTS_FAILURE,
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  // MONTHLY REPORTS FOR USER

  const updateMonthlyReport = async (options, constants) => dispatch(
    entityUpdate({
      data: options,
      constants: constants || [
        'UPDATE_MONTHLY_REPORT_REQUEST',
        'UPDATE_MONTHLY_REPORT_SUCCESS',
        'UPDATE_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const updateDayInMonthlyReport = async (entityUUID, newDays, constants) => dispatch(
    entityUpdate({
      data: {
        entity_uuid: entityUUID,
        entity_type: 'monthlyReport',
        params: {
          days: newDays,
        },
      },
      constants: constants || [
        'UPDATE_DAY_IN_MONTHLY_REPORT_REQUEST',
        'UPDATE_DAY_IN_MONTHLY_REPORT_SUCCESS',
        'UPDATE_DAY_IN_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
    }),
  );

  const getOrCreateMonthlyReport = (parentUUID, options, constants) => dispatch(
    getOrCreateSingle({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        key: getMD5HashFromString(`monthlyReport${parentUUID}${options?.params?.date}${options?.actor ?? myProfileInfo?.uuid}`),
        ...options,
      },
      constants: constants || [
        'GET_MONTHLY_REPORT_REQUEST',
        'GET_MONTHLY_REPORT_SUCCESS',
        'GET_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  const getMonthlyReport = (parentUUID, options, constants) => dispatch(
    getListAndReadEntities({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        ...options,
      },
      constants: constants || [
        'GET_MONTHLY_REPORT_REQUEST',
        'GET_MONTHLY_REPORT_SUCCESS',
        'GET_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );
  // {
  // console.log(options);
  // getOrCreateSingle
  // const { data = [], total = 0 } = await dispatch(
  //   getListAndReadEntities({
  //     data: {
  //       parent: parentUUID,
  //       entity_type: 'monthlyReport',
  //       with_files: false,
  //       ...options,
  //     },
  //     constants: [
  //       'GET_MONTHLY_REPORT_REQUEST',
  //       'GET_MONTHLY_REPORT_SUCCESS',
  //       'GET_MONTHLY_REPORT_FAILURE',
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //     doNotCheckError: true,
  //   }),
  // );

  // if (total === 0) {
  //   const res = await dispatch(
  //     entityCreate({
  //       data: {
  //         parent: parentUUID,
  //         entity_type: 'monthlyReport',
  //         params: {
  //           isAuto: true,
  //           actorUuid: options?.params?.actorUuid || myProfileInfo.uuid,
  //           date: options?.params?.date,
  //           statistics: {
  //             fullTotalWorks: 0,
  //             office_work: 0,
  //             remote_work: 0,
  //             work_on_day_off: 0,
  //             totalWorks: 0,
  //             sickday: 0,
  //             holidays: 0,
  //             vacation: 0,
  //             projectDayOffs: 0,
  //             dayoff: 0,
  //             localHoliday: 0,
  //             totalDayOff: 0,
  //             comment: '',
  //           },
  //           days: [],
  //           logs: [],
  //         },
  //       },
  //       constants: [
  //         'CREATE_MONTHLY_REPORT_REQUEST',
  //         'CREATE_MONTHLY_REPORT_SUCCESS',
  //         'CREATE_MONTHLY_REPORT_FAILURE',
  //       ],
  //       partition: SERVICE_PARTITION_NAME,
  //       doNotCheckError: true,
  //     }),
  //   );
  //   return res?.[0];
  // }
  // return data?.[0];
  // };

  const getListOfMonthlyReports = (parentUUID, options) => dispatch(
    getListAndReadEntities({
      data: {
        parent: parentUUID,
        entity_type: 'monthlyReport',
        with_files: false,
        depth: 0,
        ...options,
      },
      constants: [
        'GET_LIST_OF_MONTHLY_REPORT_REQUEST',
        'GET_LIST_OF_MONTHLY_REPORT_SUCCESS',
        'GET_LIST_OF_MONTHLY_REPORT_FAILURE',
      ],
      partition: SERVICE_PARTITION_NAME,
      doNotCheckError: true,
    }),
  );

  // INSTRUCTION
  // const createInstruction = (entityUUID, options = {}) => dispatch(
  //   entityCreate({
  //     data: options,
  //     constants: [
  //       'CREATE_INSTRUCTION_REQUEST',
  //       'CREATE_INSTRUCTION_SUCCESS',
  //       'CREATE_INSTRUCTION_FAILURE',
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //   }),
  // );

  // const requestGetInstructions = (entityUUID, options, constants) => dispatch(
  //   getListAndReadEntities({
  //     data: {
  //       entity_type: 'instruction',
  //       parent: entityUUID,
  //       depth: 0,
  //       with_files: true,
  //       ...options || {},
  //     },
  //     constants: constants || [
  //       'GET_INSTRUCTION_REQUEST',
  //       'GET_INSTRUCTION_SUCCESS',
  //       'GET_INSTRUCTION_FAILURE',
  //     ],
  //     partition: SERVICE_PARTITION_NAME,
  //     with_files: true,
  //   }),
  // );

  // const requestListInstructions = (entityUUID) => dispatch(getListOfEntities({
  //   data: {
  //     entity_type: 'instruction',
  //     parent: entityUUID,
  //   },
  //   constants: [
  //     'LIST_INSTRUCTIONS_REQUEST',
  //     'LIST_INSTRUCTIONS_SUCCESS',
  //     'LIST_INSTRUCTIONS_FAILURE',
  //   ],
  //   options: {
  //     partition: SERVICE_PARTITION_NAME,
  //   },
  // }));

  // const requestDeleteInstructions = (entityUUIDs) => dispatch(entityDelete({
  //   data: {
  //     entity_type: 'instruction',
  //     entity_uuids: entityUUIDs,
  //   },
  //   constants: [
  //     'DELETE_INSTRUCTION_REQUEST',
  //     'DELETE_INSTRUCTION_SUCCESS',
  //     'DELETE_INSTRUCTION_FAILURE',
  //   ],
  //   options: {
  //     partition: SERVICE_PARTITION_NAME,
  //   },
  // }));

  return {
    // getRootEntities,
    // getRootEntitiesSignal,
    checkEntityPerm,

    getRootUser,
    readRootUser,
    updateUserEntity,

    getMasqueradeUser,
    getDefaultDepartment,
    // DELIVERY USERS
    getDeliveryUsers,
    updateDeliveryUser,

    // STORAGES
    getVacationStorage,
    getMonthlyReportsStorage,

    requestGetPublicInterfaces,
    requestGetPartitions,
    requestAuthorization,
    requestGetMyPermissions,
    requestGetOpenInterfaces,

    // USER TIMELINE STATUSES
    // requestCreateUserTimelineStatus,
    // requestGetUserTimeline,
    // requestUpdateUserTimelineStatus,

    // USER VACATIONS
    requestCreateUserVacation,
    getUserVacationsRequests,
    requestUpdateUserVacation,
    requestDeleteUserVacation,

    // USER HOLIDAYS
    requestCreateUserHolidays,
    getUserHolidaysRequests,
    requestUpdateUserHolidays,
    requestDeleteUserHolidays,

    // VACATIONS
    getListOfVacations,
    getAllUserVacationsRequests,
    getAgreedVacations,
    createAgreedVacation,
    getVacation,
    getRequestVacation,

    // USER DAY OFF
    requestGetYearDayOff,
    getUserDayOffsRequest,
    requestCreateUserDayOff,
    requestUpdateUserDayOff,
    requestDeleteDayOff,

    // HOLIDAYS
    createAgreedHolidays,
    getAgreedHolidays,

    incrementAndGetCount,

    // REPORT
    requestGetYearReports,
    createReport,
    createReportWithFiles,
    getMonthReports,
    getLastMonthReports,

    // MONTHLY REPORTS FOR USER
    updateMonthlyReport,
    updateDayInMonthlyReport,
    getOrCreateMonthlyReport,
    getMonthlyReport,

    // MONTHLY REPORTS FOR ADMIN
    getListOfMonthlyReports,

    // INSTRUCTION
    // createInstruction,
    // requestGetInstructions,
    // requestDeleteInstructions,
    // requestListInstructions,

    // PERMS
    getPermissionsOfEntity,
    createEntityPermissions,
    deleteEntityPerm,
  };
};

export default useAPI;
