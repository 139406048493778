import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Tabs } from 'antd';
import { get } from 'lodash';
import { getDeliveryPartition } from '../../../../redux/config/selectors';
import PermissionsStartPage from '../../../../components/permissions/PermissionsStartPage';
import useAPI from '../../../../api/useAPI';
import { getArtifactsStorageUUID } from '../../../../redux/departments/selectors/artifactsStorageSelector';
import { getOrCreateArtifactsStorage } from '../../../../redux/departments/actions/artifactsActions';

export default function AdminPermissions({}) {
  const dispatch = useDispatch();

  const rootPartitionUuid = useSelector(getDeliveryPartition);
  const artifactsStorageUUID = useSelector(getArtifactsStorageUUID);

  const [activeKey, setActiveKey] = useState('1');

  const [defaultDepartment, setDefaultDepartment] = useState(null);

  const {
    getDefaultDepartment,
  } = useAPI();

  const initFunc = async () => {
    dispatch(getOrCreateArtifactsStorage(rootPartitionUuid));
    const res = await getDefaultDepartment();
    if (res) {
      setDefaultDepartment(get(res, 'uuid', null));
    }
  };

  useEffect(() => {
    if (rootPartitionUuid) {
      initFunc();
    }
  }, [JSON.stringify(rootPartitionUuid)]);

  return (
    <Flex
      vertical
      className="w-full"
    >
      <Tabs
        type="card"
        className="mb-2"
        defaultActiveKey="1"
        activeKey={activeKey}
        items={[
          {
            key: '1',
            label: 'Delivery partition permissions',
          },
          {
            key: '2',
            disabled: !defaultDepartment,
            label: 'Default department permissions',
          },
          {
            key: '3',
            disabled: !artifactsStorageUUID,
            label: 'Default artifacts storage permissions',
          },
        ]}
        onChange={setActiveKey}
        tabBarStyle={{
          marginBottom: 0,
          borderRadius: '0px',
        }}
      />
      {activeKey === '1' && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={rootPartitionUuid}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Delivery partition permissions"
        />
      )}
      {activeKey === '2' && defaultDepartment && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={defaultDepartment}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Default department permissions"
        />
      )}
      {activeKey === '3' && artifactsStorageUUID && (
        <PermissionsStartPage
          options={{
            personalCardClassName: 'orange-base-card',
          }}
          isModalMode={false}
          entityUUID={artifactsStorageUUID}
          partitionType="DELIVERY"
          className="mt-2"
          nameSectionPermissions="Default artifact storage permissions"
        />
      )}
    </Flex>
  );
}
