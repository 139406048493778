import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import AppContent from './AppContent';
import AppFooter from './AppFooter';
import AppHeader from './AppHeader';
import AppSidebar from './AppSidebar';

import { getDeliveryPartition } from '../../redux/config/selectors';
import useAPI from '../../api/useAPI';
import { getProfileInfo, isRootOrAdmin } from '../../redux/profile/selectors';
import CheckLastMonthReport from './CheckLastMonthReport';
import GroupsConstants from '../../redux/groups/constants';
import useActorsAPI from '../../api/useActorsApi';
import {
  getMonthlyStorageUUID, getUserRootFetching, getUserRootUUID, getVacationStorageUUID,
} from '../../redux/storages/selectors';
import useUserRootEntity from '../../hooks/useUserRootEntity';

const layoutStyle = {
  minHeight: '100vh',
};

export default function AppLayout() {
  const dispatch = useDispatch();

  const {
    getRootUser,
    getVacationStorage,
    getMonthlyReportsStorage,
  } = useAPI();

  const { getListOfAllActors } = useActorsAPI();

  const myProfileInfo = useSelector(getProfileInfo);
  const partitionUUID = useSelector(getDeliveryPartition);

  const isUserRootOrAdmin = useSelector(isRootOrAdmin);

  const userRootUUID = useSelector(getUserRootUUID);
  const isFetchingUser = useSelector(getUserRootFetching);

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const getGroups = () => {
    const defaultGroups = ['BAN', 'ADMIN', 'DEFAULT'];

    const publicConfig = {
      actor_type: 'group',
      uinfo: {
        group_name: defaultGroups,
      },
    };

    const constants = [
      GroupsConstants.FETCH_GROUPS_REQUEST,
      GroupsConstants.FETCH_GROUPS_SUCCESS,
      GroupsConstants.FETCH_GROUPS_FAILURE,
    ];

    getListOfAllActors(publicConfig, constants);
  };

  useUserRootEntity();

  const initFunc = async () => {
    // getRootUser();
    if (isUserRootOrAdmin) {
      getGroups();
    } else {
      dispatch({ type: GroupsConstants.FETCH_GROUPS_FAILURE });
    }
  };

  useEffect(() => {
    if (partitionUUID && myProfileInfo?.uuid) {
      initFunc();
    }
  }, [partitionUUID, JSON.stringify(myProfileInfo?.uuid)]);

  useEffect(() => {
    if (!isFetchingUser
      && myProfileInfo?.uuid
      && userRootUUID
      && !vacationStorageUUID
      && !monthlyStorageUUID
    ) {
      getVacationStorage(userRootUUID, myProfileInfo?.uuid);
      getMonthlyReportsStorage(userRootUUID, myProfileInfo?.uuid);
    }
  }, [userRootUUID, myProfileInfo?.uuid, isFetchingUser, monthlyStorageUUID, vacationStorageUUID]);

  return (
    <Layout style={layoutStyle}>
      <>
        <AppSidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <CheckLastMonthReport />
        <Layout>
          <AppHeader
            isSidebarCollapsed={isSidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <div className="relative h-full w-full mb-2">
            <AppContent />
          </div>
          <AppFooter />
        </Layout>
      </>
    </Layout>
  );
}
