import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  useLocation, useHistory,
} from 'react-router-dom';

import { array, string } from 'prop-types';
import dayjs from 'dayjs';
import {
  capitalize, get, head, isNil,
} from 'lodash';
import cn from 'classnames';

import { Flex } from 'antd';
import useAPI from '../../../../api/useAPI';

import { getDeliveryPartition } from '../../../../redux/config/selectors';

import { translateTypeDay } from '../../../user/dayOff/utils';
import { compareActorUUIDs, convertTimeFormat } from './utils';

import BaseTable from '../common/BaseTable/BaseTable';
import EmployeeNameForDepartment from './EmployeeNameForDepartment';
import ArtifactStatusForDepartment from './ArtifactStatusForDepartment';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';
import { getListAndReadEntities } from '../../../../api/actions/entity';

export default function EmployeesForDepartment({
  departmentUUID = '',
  leader = '',
  deputy = '',
}) {
  const dispatch = useDispatch();

  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);

  const activeArtifact = query.get('artifact');

  const {
    getMonthlyReport,
  } = useAPI();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [dataTable, setDataTable] = useState([]);

  const [users, setUsers] = useState([]);

  const today = dayjs().format('YYYY-MM-DD');

  const currentMonth = dayjs().format('YYYY-MM');
  const previousMonth = dayjs().subtract(1, 'month').format('YYYY-MM');

  const initEmployeesFunc = async () => {
    const res = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    setUsers(res?.data ?? []);
  };

  const initMonthlyReports = async () => {
    if (!users?.length) {
      setDataTable([]);
      return;
    }

    const res = await getMonthlyReport(partitionUUID, {
      depth: 0,
      params: {
        date: [currentMonth, previousMonth],
      },
    });

    const reports = res?.data ?? [];

    setDataTable(users.map((user) => {
      const previousRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === previousMonth);
      const currentRep = reports
        .find((el) => compareActorUUIDs(el, user) && el?.params?.date === currentMonth);

      return {
        ...user,
        prevMonthRep: previousRep ?? null,
        currentMonthRep: currentRep ?? null,
      };
    }));
  };

  const columns = [
    {
      title: 'Имя',
      key: 'name',
      dataIndex: ['uinfo'],
      width: '40%',
      render: (cell, row) => (
        <EmployeeNameForDepartment
          user={row}
          deputy={deputy}
          leader={leader}
        />
      ),
    },
    {
      title: 'Cегодня',
      key: 'currentMonthRep',
      dataIndex: ['currentMonthRep'],
      width: '25%',
      render: (cell, row, index) => {
        const todayReport = cell?.params?.days?.find((el) => head(el?.rangeDates) === today);
        return todayReport ? (
          <Flex
            className="text-base"
          >
            {todayReport?.type === 'office_work' ? (
              <Flex
                vertical
                className="mr-6 text-blue-500"
              >
                {translateTypeDay[todayReport?.type]}
                <span>
                  (
                  {convertTimeFormat(todayReport?.startWork)}
                  -
                  {convertTimeFormat(todayReport?.finishWork)}
                  )
                </span>
              </Flex>
            ) : (
              <span
                className="mr-6 text-orange-500"
              >
                {translateTypeDay[todayReport?.type]}
              </span>
            )}
          </Flex>
        ) : <span className="text-red-500">Нет отчета</span>;
      },
    },
    {
      title: `Отчет за ${capitalize(dayjs().subtract(1, 'month').format('MMMM'))}`,
      key: 'prevMonthRep',
      dataIndex: ['prevMonthRep'],
      width: '30%',
      render: (cell) => (cell?.params?.status ? (
        <Flex className="text-green-500">
          {cell?.params?.status === 'submitted' && 'Отправлен'}
          {cell?.params?.status === 'auto' && 'Автоматический'}
        </Flex>
      ) : <span className="text-red-500">Отсутствует</span>),
    }, {
      title: 'Добавлен',
      key: 'created',
      dataIndex: ['created'],
      width: '20%',
      render: (cell) => dayjs(cell).format('YYYY-MM-DD'),
    }];

  const onRow = (record) => ({
    onClick: () => {
      const uuid = get(record, 'uuid', '');

      query.set('artifact', uuid);

      history.push({
        pathname: location.pathname,
        search: query.toString(),
      });
    },
  });

  const rowClassName = (row) => cn('cursor-pointer hover:bg-gray-100', {
    '!bg-sky-100': row?.uuid === activeArtifact,
  });

  useEffect(() => {
    if (departmentUUID) {
      initEmployeesFunc();
    }
  }, [departmentUUID]);

  useEffect(() => {
    if (departmentUUID) {
      initMonthlyReports();
    }
  }, [JSON.stringify(users), departmentUUID]);

  return (
    <BaseTable
      data={dataTable}
      columns={columns}
      rowClassName={rowClassName}
      size="small"
      rowKey="uuid"
      onRow={onRow}
      className={cn('w-full h-full !mb-3')}
      useCustomPagination
      disablePagination
    />
  );
}

EmployeesForDepartment.propTypes = {
  departmentUUID: string,
  leader: string,
  deputy: string,
};
