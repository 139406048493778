import React, { useMemo, useState } from 'react';

import {
  AutoSizer, List,
} from 'react-virtualized';

import {
  Button, Flex, Popover, Tooltip,
} from 'antd';
import {
  any, array, func, string,
} from 'prop-types';
import Icon from '@mdi/react';
import { mdiPencil, mdiWindowClose } from '@mdi/js';
import cn from 'classnames';
import useActorsHRM54 from '../../../../actors/hooks/useActorsHRM54';
import AntDesignSearchBox54origins from '../../../../54origins/components/AntDesignSearchBox54origins';

export default function AssigningUserPopover({
  parent,
  title = '',
  placement = 'leftBottom',
  selected = null,
  onUserSelect,
  popoverChildTitle = null,
}) {
  const {
    actors, total, config, setConfig,
    onChangeSearch,
    valueSearch,
  } = useActorsHRM54({
    defaultConfig: {
      parent,
    },
  });

  const {
    actor = '',
    label: currentLabel = '',
    first_name = '',
    last_name = '',
  } = selected || {};

  const label = currentLabel || `${first_name} ${last_name}`;

  const [isEdit, setIsEdit] = useState(false);

  const onClose = () => setIsEdit(false);
  const toggleBtn = () => setIsEdit((prev) => !prev);

  const handleScroll = ({ scrollTop, clientHeight, scrollHeight }) => {
    const { limit = 100, offset = 0 } = config;

    if (scrollTop + clientHeight >= scrollHeight - 400
          && setConfig) {
      const newOffset = offset + limit;
      if (newOffset < total) {
        setConfig((prev) => ({
          ...prev,
          offset: newOffset,
        }));
      }
    }
  };

  const rowRenderer = ({ index, style, isScrolling }) => {
    if (actors[index] === undefined && isScrolling) {
      return (
        <div key={index} style={style}>
          Scrolling...
        </div>
      );
    }

    const {
      value, actor: actorLabel, label: userLabel,
    } = actors[index];

    return (
      <Flex
        vertical
        justify="center"
        align="center"
        key={value}
        style={{ ...style, borderBottom: '1px solid #D3D3D3' }}
        className={cn('cursor-pointer', {
          'bg-sky-50': actor === actorLabel,
        })}
        onClick={() => onUserSelect(actors[index])}
      >
        <Flex
          vertical
          className="w-full"
        >
          <Tooltip
            title={userLabel}
            style={{
              maxWidth: 380,
            }}
          >
            {userLabel.length > 20 ? `${userLabel.slice(0, 20)}...` : userLabel}
          </Tooltip>
        </Flex>
      </Flex>
    );
  };

  return (
    <Popover
      open={isEdit}
      placement={placement}
      trigger="click"
      destroyTooltipOnHide
      onClick={toggleBtn}
      content={(
        <Flex
          vertical
          className="-mt-2"
        >
          <Flex
            className="w-full"
            justify="space-between"
          >
            <span
              className="mr-2 mb-1 text-lg font-medium"
            >
              {title}
            </span>
            <Flex
              onClick={onClose}
              className="cursor-pointer text-stone-500"
            >
              <Icon path={mdiWindowClose} size={1} />
            </Flex>
          </Flex>
          <Flex
            vertical
            className="rounded p-2"
            style={{
              height: 420,
              border: '1px dashed gray',
            }}
          >
            <AntDesignSearchBox54origins
              maxWidth={350}
              valueSearch={valueSearch}
              onSearch={onChangeSearch}
            />
            <div
              className="bg-gray-200 w-full mt-3.5 mb-0"
              style={{ height: 1 }}
            />
            <AutoSizer
              className="w-100 h-full mb-2 pb-6"
            >
              {({ width, height }) => (
                <List
                  height={360}
                  rowHeight={45}
                  width={width}
                  rowCount={actors?.length || 0}
                  onScroll={handleScroll}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          </Flex>
        </Flex>

    )}
    >
      <Flex
        align="center"
        // className={className}
      >
        {popoverChildTitle || (
        <span
          className={cn('mr-2 text-base')}
        >
          {title}
        </span>
        )}
        <Button
          size="small"
          shape="default"
          className={cn('!w-7 mr-2 flex items-center justify-center !h-7 p-0 !rounded-full border-white text-blue-500 hover:!border-blue-500', {})}
        >
          <Flex>
            <Icon
              path={mdiPencil}
              size={0.85}
            />
          </Flex>
        </Button>
        {selected?.actor
          ? (
            <span
              className="text-blue-500"
            >
              {label > 25 ? (
                <Tooltip
                  title={label}
                >
                  {`${label?.slice(0, 25)}...`}
                </Tooltip>
              ) : label}
            </span>
          ) : (
            <span
              className="text-gray-500"
            >
              Отсутствует
            </span>
          )}
      </Flex>
    </Popover>
  );
}

AssigningUserPopover.propTypes = {
  parent: string,
  popoverChildTitle: any,
  selected: array,
  onUserSelect: func,
  title: string,
};
