import {
  mdiArrowLeft, mdiChevronDown, mdiChevronUp,
} from '@mdi/js';
import Icon from '@mdi/react';
import {
  Button, Card, Divider, Flex,
  Modal,
  Tabs,
} from 'antd';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  capitalize,
  get, head,
} from 'lodash';
import { func, object, string } from 'prop-types';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import useAPI from '../../../api/useAPI';
import CommonInfo from '../../admin/departments/common/CommonInfo';
import EntityDescriptionForCard from '../../admin/departments/common/EntityDescriptionForCard';
import EntityTitleForCard from '../../admin/departments/common/EntityTitleForCard';
import VacationsForDepartment from '../../admin/departments/department/VacationsForDepartment';

const items = [
  // {
  //   key: '1',
  //   label: 'Сотрудники',
  // },
  {
    key: '2',
    label: 'Отпуска',
  },
  // {
  //   key: '3',
  //   label: 'Отчеты сотрудников',
  // },
  // {
  //   key: '4',
  //   label: 'Статистика',
  // },
];

export default function MyDepartmentCard({
  department = {},
  departmentUUID = '',
  className = '',
  onBackDepartmentCallback,
  onEditDepartmentCallback,
  onDeleteDepartmentCallback,
}) {
  const dispatch = useDispatch();

  const partitionUUID = useSelector(getDeliveryPartition);

  const [isGlobalEdit, setIsGlobalEdit] = useState(false);
  const [activeKey, setActiveKey] = useState('2');

  const {
    uuid,
    params: {
      leader,
      deputy,
      leaderActorUUID,
      deputyActorUUID,
      title,
      description,
    } = {},
  } = department || {};
  return (
    <Card
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
          >
            <Flex
              align="center"
              className="cursor-pointer"
            >
              {/* <Icon path={mdiArrowLeft} size={0.9} /> */}
              <span className="ml-3 text-xl">
                Мой отдел
              </span>
            </Flex>
            <CommonInfo
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              className="ml-2 mt-1"
              creator={`${capitalize(get(department, 'uinfo.first_name', ''))} ${get(department, 'uinfo.last_name', '')}`}
              createdDate={get(department, 'created', '')}
            />
          </Flex>
        </Flex>
      )}
      className={cn(`w-full h-max p-0 ${className}`, {
        'blue-base-card': true,
      })}
    >
      <Flex
        vertical
        className="py-2"
      >
        <EntityTitleForCard
          className="mx-12"
          hideEditButton
          isGlobalEdit={isGlobalEdit}
          title={title}
        />
        <Divider className="my-2" />
        <Flex
          justify="space-between"
          className="mx-12 text-sm font-normal"
        >
          <Flex
            align="center"
          >
            <span
              className="mr-2"
            >
              Руководитель:
            </span>
            {!isGlobalEdit && leader && (
              <span className="text-blue-500">
                {get(leader, 'first_name', '')}
                {' '}
                {get(leader, 'last_name', '')}
              </span>
            )}
            {!isGlobalEdit && !leader && (
              <span
                className="text-gray-500"
              >
                Нет руководителя
              </span>
            )}
          </Flex>
          <Flex
            align="center"
          >
            <span
              className="mr-2"
            >
              Заместитель:
            </span>
            {!isGlobalEdit && deputy && (
              <span className="text-blue-500">
                {get(deputy, 'first_name', '')}
                {' '}
                {get(deputy, 'last_name', '')}
              </span>
            )}
            {!isGlobalEdit && !deputy && (
              <span
                className="text-gray-500"
              >
                Нет заместителя
              </span>
            )}
          </Flex>
        </Flex>
        <Divider className="my-2" />
        <EntityDescriptionForCard
          hideEdit
          isGlobalEdit={isGlobalEdit}
          className="mx-12"
          description={description}
        />
        <Divider className="my-2" />
        <Flex
          vertical
          className="mx-6 -mt-2"
        >
          <Tabs
            // type="card"
            defaultActiveKey="2"
            activeKey={activeKey}
            items={items}
            onChange={setActiveKey}
            tabBarStyle={{ marginBottom: 0 }}
          />
          {/* {activeKey === '1' && (
            <EmployeesForDepartment
              departmentUUID={departmentUUID}
              leader={leaderActorUUID}
              deputy={deputyActorUUID}
            />
          )} */}
          {activeKey === '2' && (
            <VacationsForDepartment
              departmentUUID={departmentUUID}
            />
          )}
          {/* {activeKey === '3' && (
            <ReportsForDepartment
              departmentUUID={departmentUUID}
              leader={leaderActorUUID}
              deputy={deputyActorUUID}
            />
          )}
          {activeKey === '4' && (
            <PanelForDepartment
              departmentUUID={departmentUUID}
            />
          )} */}
        </Flex>
      </Flex>
    </Card>
  );
}

MyDepartmentCard.propTypes = {
  parent: string,
  department: object,
  className: string,
  departmentUUID: string,
  onBackDepartmentCallback: func,
  onEditDepartmentCallback: func,
  onDeleteDepartmentCallback: func,
};
