import {
  Button,
  Card, Divider, Flex, Modal, Segmented, Table, Tag,
  Upload,
} from 'antd';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import { func, string } from 'prop-types';
import {
  capitalize, get, head, omit,
} from 'lodash';
import dayjs from 'dayjs';
import cn from 'classnames';
import { getArtifact, getArtifactUUID } from '../../../../redux/departments/selectors/artifactsSelector';
import { getDepartment } from '../../../../redux/departments/selectors/departmentsSelector';
import UserArtifactView from './UserArtifactView';
import InstructionArtifactView from './InstructionArtifactView';
import FileArtifactView from './FileArtifactView';
import { deleteSingleArtifact, updateSingleArtifact } from '../../../../redux/departments/actions/artifactsActions';
import CommonInfo from '../common/CommonInfo';
import CopyURL from '../../../../components/commonComponents/CopyURL';
import { removeFile } from '../../../../api/actions/entity';
import { UPDATE_SINGLE_ARTIFACT_FAILURE, UPDATE_SINGLE_ARTIFACT_REQUEST, UPDATE_SINGLE_ARTIFACT_SUCCESS } from '../../../../redux/departments/slices/artifactsSlice';
import { getArtifactsStorageUUID } from '../../../../redux/departments/selectors/artifactsStorageSelector';
import useAPI from '../../../../api/useAPI';

export default function ArtifactCard({
  className = '',
  onBackArtCallback,
  onDeleteArtCallback,
  onUpdateArtCallback,
}) {
  const dispatch = useDispatch();

  const artifact = useSelector(getArtifact);
  const artifactUUID = useSelector(getArtifactUUID);

  const artifactsStorageUUID = useSelector(getArtifactsStorageUUID);

  const { getDefaultDepartment } = useAPI();

  const {
    uuid,
    entity_type: entityType,
  } = artifact || {};

  const {
    updateUserEntity,
    createEntityPermissions,
  } = useAPI();

  const getCardName = (type) => {
    switch (type) {
      case 'file': {
        return 'Файл';
      }
      case 'instruction': {
        return 'Инструкция';
      }
      case 'schedule': {
        return 'Расписание';
      }
      case 'user': {
        return 'Сотрудник';
      }
      default: return '';
    }
  };

  const onDeleteArtifact = async () => {
    if (artifact?.entity_type === 'user') {
      Modal.warning({
        title: `Сотрудник ${get(artifact, ['uinfo', 'first_name'], '')} ${get(artifact, ['uinfo', 'last_name'], '')}`,
        content: (
          <Flex
            vertical
          >
            <span>
              Вы уверены, что хотите удалить сотрудника из отдела?
            </span>
            <span>
              Сотрудник станет доступен для назначения в другой отдел.
            </span>
          </Flex>
        ),
        width: 'auto',
        centered: true,
        keyboard: true,
        maskClosable: true,
        closable: true,
        okText: 'Удалить',
        onOk: async () => {
          if (artifactUUID) {
            const defaultDepartment = await getDefaultDepartment();
            updateUserEntity(artifactUUID, {
              parent: defaultDepartment?.uuid,
            });

            createEntityPermissions({
              data: {
                entity_uuid: artifact?.parent,
                data: [{
                  actor: artifact?.actor,
                  create: false,
                  list: false,
                  read: false,
                  update: false,
                  delete: false,
                  set: false,
                }],
              },
            });
            if (onDeleteArtCallback) {
              onDeleteArtCallback();
            }
          }
        },
      });
    } else {
      Modal.warning({
        title: `Артефакт ${get(artifact, ['params', 'title'], '')}`,
        content: 'Вы уверены, что хотите удалить артефакт?',
        width: 'auto',
        centered: true,
        keyboard: true,
        maskClosable: true,
        closable: true,
        okText: 'Удалить',
        onOk: async () => {
          if (artifactUUID) {
            await dispatch(deleteSingleArtifact(artifactUUID));
            if (onDeleteArtCallback) {
              onDeleteArtCallback();
            }
          }
        },
      });
    }
  };

  const onUpdateArtifact = async (
    data = null,
    options = {},
  ) => {
    if (artifactUUID) {
      const config = {
        entity_uuid: artifactUUID,
        ...omit(options, ['file']),
      };

      if (data) {
        config.params = { ...data };
      }
      const newFormData = new FormData();

      newFormData.append('data', JSON.stringify(config));

      if (options?.file?.length !== 0) {
        for (let i = 0; i < options?.file?.length; i += 1) {
          newFormData.append('file', options?.file?.[i]);
        }
      }

      const res = await dispatch(updateSingleArtifact(newFormData));

      if (onUpdateArtCallback) {
        onUpdateArtCallback(head(res));
      }
    }
  };

  const onDeleteFileCallback = async (file) => {
    if (file) {
      const res = await dispatch(removeFile(artifactUUID, file?.uuid, 'file', [
        UPDATE_SINGLE_ARTIFACT_REQUEST,
        UPDATE_SINGLE_ARTIFACT_SUCCESS,
        UPDATE_SINGLE_ARTIFACT_FAILURE,
      ]));

      if (onUpdateArtCallback) {
        onUpdateArtCallback(head(res));
      }
    }
  };

  return (
    <Card
      className={cn(`w-full h-max p-0 ${className}`, {
        'green-base-card': artifact?.entity_type === 'user',
        'blue-base-card': artifact?.entity_type === 'schedule'
      || artifact?.entity_type === 'instruction'
      || artifact?.entity_type === 'file',
      })}
      title={(
        <Flex
          className="w-full"
          justify="space-between"
        >
          <Flex
            align="center"
          >
            <Flex
              onClick={onBackArtCallback}
              align="center"
              className="cursor-pointer"
            >
              <Icon path={mdiArrowLeft} size={0.9} />
              <span className="ml-3 text-xl">
                {getCardName(artifact?.entity_type)}
              </span>
            </Flex>
            <CommonInfo
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              className="ml-2 mt-1"
              creator={`${capitalize(get(artifact, 'uinfo.first_name', ''))} ${get(artifact, 'uinfo.last_name', '')}`}
              createdDate={get(artifact, 'created', '')}
            />
            <CopyURL
              entityType="artifact"
              entityUUID={uuid}
              className="ml-1 pt-2.5 mt-0.5 pl-2"
              iconSize={1}
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
            />
          </Flex>
          <Flex>
            {/* <Button
              size="small"
              className="!text-blue-500 border!border-blue-500 hover:bg-blue-100"
            >
              История
            </Button> */}
            {/* {artifact?.entity_type !== 'user' && ( */}
            <Button
              size="small"
              onClick={onDeleteArtifact}
              className="ml-2 !text-red-500 border !border-red-500 hover:bg-red-100"
            >
              Удалить
            </Button>
            {/* )} */}
          </Flex>
        </Flex>
          )}
    >
      <Flex
        vertical
        className="py-2"
      >
        {artifact?.entity_type === 'user' && (
        <UserArtifactView
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
        {artifact?.entity_type === 'instruction' && (
        <InstructionArtifactView
          artifactsStorage={artifactsStorageUUID}
          onUpdateArtifactCallback={onUpdateArtifact}
          artifact={artifact}
        />
        )}
        {artifact?.entity_type === 'file' && (
        <FileArtifactView
          artifactsStorage={artifactsStorageUUID}
          onUpdateArtifactCallback={onUpdateArtifact}
          onDeleteFileCallback={onDeleteFileCallback}
          artifact={artifact}
        />
        )}
      </Flex>
    </Card>
  );
}

ArtifactCard.propTypes = {
  storageUUID: string,
  onBackArtCallback: func,
  onDeleteArtCallback: func,
  onUpdateArtCallback: func,
};
