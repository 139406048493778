import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import {
  capitalize, get, head, isNil,
} from 'lodash';
import dayjs from 'dayjs';
import { Divider, Flex, Segmented } from 'antd';
import { array } from 'prop-types';
import { useDispatch } from 'react-redux';
import useAPI from '../../../../api/useAPI';
import BaseTable from '../common/BaseTable/BaseTable';
import { getListAndReadEntities } from '../../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../../api/app_config';

export default function PanelForDepartment({
  departmentUUID = '',
}) {
  const dispatch = useDispatch();

  const {
    getMonthlyReport,
  } = useAPI();

  const [data, setData] = useState([]);
  const [selectedType, setSelectedType] = useState('all');
  const [users, setUsers] = useState([]);

  const options = [{
    label: 'Всего часов',
    value: 'all',
  }, {
    label: 'В среднем на сотрудника',
    value: 'average',
  }].filter((el) => !el?.hidden);

  const previous1Month = dayjs().subtract(1, 'month').format('YYYY-MM');
  const previous2Month = dayjs().subtract(2, 'month').format('YYYY-MM');
  const previous3Month = dayjs().subtract(3, 'month').format('YYYY-MM');

  const columns = [
    {
      title: 'Месяц',
      key: 'month',
      dataIndex: ['month'],
      width: '20%',
      render: (cell) => capitalize(dayjs(cell).format('MMMM')),
    },
    {
      title: 'Проектный KPI',
      key: 'kpi',
      dataIndex: ['kpi'],
      width: '20%',
      render: (cell, row) => {
        if (!row?.internalHours || !row?.clientHours) return null;
        return (row?.internalHours / row?.clientHours).toFixed(2);
      },
    },
    {
      title: 'Клиентские часы',
      key: 'clientHours',
      dataIndex: ['clientHours'],
      width: '20%',
      render: (cell) => {
        if (selectedType === 'all') {
          return cell;
        } if (selectedType === 'average') {
          return (cell / (users?.length ?? 1)).toFixed();
        }
        return null;
      },
    },
    {
      title: 'Внутренние часы',
      key: 'internalHours',
      dataIndex: ['internalHours'],
      width: '20%',
      render: (cell) => {
        if (selectedType === 'all') {
          return cell;
        } if (selectedType === 'average') {
          return (cell / (users?.length ?? 1)).toFixed();
        }
        return null;
      },
    },
    {
      title: 'Всего часов',
      key: 'total',
      dataIndex: ['total'],
      width: '20%',
      render: (cell) => {
        if (selectedType === 'all') {
          return cell;
        } if (selectedType === 'average') {
          return (cell / (users?.length ?? 1)).toFixed();
        }
        return null;
      },
    },
  ];

  const initMonthlyReports = async () => {
    const res = await getMonthlyReport(departmentUUID, {
      depth: 0,
      params: {
        date: [previous1Month, previous2Month, previous3Month],
      },
    });

    const reports = res?.data ?? [];

    const newData = reports?.reduce((acc, report) => {
      const currMonth = acc?.find((el) => el?.month === report?.params?.date);
      if (currMonth) {
        currMonth.internalHours += +get(report, 'params.statistics.internalWork', 0);
        currMonth.clientHours += +get(report, 'params.statistics.clientWork', 0);
        currMonth.total += +get(report, 'params.statistics.totalWorks', 0);
      }
      return acc;
    }, [{
      month: previous1Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }, {
      month: previous2Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }, {
      month: previous3Month,
      total: 0,
      internalHours: 0,
      clientHours: 0,
    }]);

    setData(newData);
  };

  const initEmployeesFunc = async () => {
    const res = await dispatch(
      getListAndReadEntities({
        data: {
          entity_type: 'user',
          parent: departmentUUID,
        },
        constants: [
          'GET_EMPLOYEES_FOR_DEPARTMENT_REQUEST',
          'GET_EMPLOYEES_FOR_DEPARTMENT_SUCCESS',
          'GET_EMPLOYEES_FOR_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }),
    );

    setUsers(res?.data ?? []);
  };

  useEffect(() => {
    if (departmentUUID) {
      initMonthlyReports();
      initEmployeesFunc();
    }
  }, [departmentUUID]);

  return (
    <Flex
      vertical
    >
      {/* <Segmented
        className="my-2"
        options={options}
        value={selectedType}
        onChange={setSelectedType}
      /> */}
      <BaseTable
        data={data}
        columns={columns}
        // rowClassName={rowClassName}
        size="small"
        rowKey="uuid"
        // onRow={onRow}
        className={cn('w-full h-full !mb-3')}
        useCustomPagination
        disablePagination
      />
    </Flex>
  );
}

PanelForDepartment.propTypes = {
  employees: array,
};
