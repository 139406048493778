/* eslint-disable import/prefer-default-export */
import {
  entityCreate, entityDelete, entityRead, entityUpdate, getListAndReadEntities,
} from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import {
  GET_DEPARTMENTS_FAILURE,
  GET_DEPARTMENTS_REQUEST,
  GET_DEPARTMENTS_SUCCESS,
  CREATE_DEPARTMENT_REQUEST,
  CREATE_DEPARTMENT_SUCCESS,
  CREATE_DEPARTMENT_FAILURE,
  GET_SINGLE_DEPARTMENT_REQUEST,
  GET_SINGLE_DEPARTMENT_SUCCESS,
  GET_SINGLE_DEPARTMENT_FAILURE,
  UPDATE_SINGLE_DEPARTMENT_REQUEST,
  UPDATE_SINGLE_DEPARTMENT_SUCCESS,
  UPDATE_SINGLE_DEPARTMENT_FAILURE,
  DELETE_SINGLE_DEPARTMENT_REQUEST,
  DELETE_SINGLE_DEPARTMENT_SUCCESS,
  DELETE_SINGLE_DEPARTMENT_FAILURE,
} from '../slices/departmentsSlice';

export const getAllDepartments = (options, parent, constants) => (dispatch) => dispatch(
  getListAndReadEntities({
    data: {
      parent,
      entity_type: 'department',
      ...options,
    },
    constants: constants || [
      GET_DEPARTMENTS_REQUEST,
      GET_DEPARTMENTS_SUCCESS,
      GET_DEPARTMENTS_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const getSingleDepartment = (options, constants) => (dispatch) => dispatch(
  entityRead({
    data: {
      entity_type: 'department',
      ...options,
    },
    constants: constants || [
      GET_SINGLE_DEPARTMENT_REQUEST,
      GET_SINGLE_DEPARTMENT_SUCCESS,
      GET_SINGLE_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const createDepartment = (parentUUID, data) => (dispatch) => dispatch(
  entityCreate({
    data: {
      entity_type: 'department',
      parent: parentUUID,
      ...data,
    },
    constants: [
      CREATE_DEPARTMENT_REQUEST,
      CREATE_DEPARTMENT_SUCCESS,
      CREATE_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const updateSingleDepartment = (data, constants) => (dispatch) => dispatch(
  entityUpdate({
    data: {
      entity_type: 'department',
      ...data,
    },
    constants: constants || [
      UPDATE_SINGLE_DEPARTMENT_REQUEST,
      UPDATE_SINGLE_DEPARTMENT_SUCCESS,
      UPDATE_SINGLE_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);

export const deleteSingleDepartment = (data) => (dispatch) => dispatch(
  entityDelete({
    data: {
      entity_type: 'department',
      ...data,
    },
    constants: [
      DELETE_SINGLE_DEPARTMENT_REQUEST,
      DELETE_SINGLE_DEPARTMENT_SUCCESS,
      DELETE_SINGLE_DEPARTMENT_FAILURE,
    ],
    partition: SERVICE_PARTITION_NAME,
  }),
);
