import React, {
  useEffect, useMemo, useState,
} from 'react';
import { capitalize } from 'lodash';
import {
  Alert, Button, Spin,
} from 'antd';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import UserStatisticsForm from './UserStatisticsForm';

import useAPI from '../../../../api/useAPI';
import { getMonthlyStorageInfo, getMonthlyStorageUUID } from '../../../../redux/storages/selectors';
import antNotification from '../../../../utils/antNotification';
import { dateToFormat4, defaultDate } from '../../../../54origins/dateFormats54origins';

export default function UserReportForLastMonth({ actor, onlySuccessTask }) {
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [monthlyReport, setMonthlyReport] = useState(null);

  const {
    updateMonthlyReport, getOrCreateMonthlyReport,
  } = useAPI();

  const {
    params: {
      statistics: reportStat,
      logs: reportLogs,
      status: reportStatus,
    } = {},
    uuid: reportUUID,
  } = monthlyReport || {};

  const lastMonthDate = dayjs().subtract(1, 'month').format('YYYY-MM');

  const initFunc = async () => {
    setLoading(true);
    const resReport = await getOrCreateMonthlyReport(
      monthlyStorageUUID,
      {
        actor,
        params: {
          date: lastMonthDate,
        },
      },
    );

    setMonthlyReport(resReport);
    setLoading(false);
  };

  const onFinish = async (values = {}, filesForReport = [], uuid = '', files = []) => {
    if (!monthlyStorageUUID || !actor) return;

    try {
      const totalWorks = Math.ceil(values?.totalWorks) || 0;
      const fullTotalWorks = values?.totalWorks || 0;
      const totalDayOff = (values?.sickday || 0) + (values?.vacation || 0) + (values?.dayoff || 0);

      const config = {
        entity_type: 'monthlyReport',
        entity_uuid: uuid || reportUUID,
        actor,
        params: {
          status: 'submitted',
          statistics: {
            ...values,
            created: defaultDate().toString(),
            totalWorks,
            fullTotalWorks,
            totalDayOff,
          },
          logs: reportLogs || [],
        },
      };

      if (reportStat) {
        const logFiles = reportLogs?.flatMap((log) => log?.files || []) || [];
        const newLogFiles = files?.map((file) => file?.uuid).filter((fileUuid) => !logFiles.includes(fileUuid)) || [];

        config.params.logs.unshift({
          ...reportStat,
          status: reportStatus,
          files: newLogFiles,
        });
      }

      const newFormData = new FormData();
      newFormData.append('data', JSON.stringify(config));

      filesForReport.forEach((file) => newFormData.append('file', file));

      await updateMonthlyReport(newFormData);
      antNotification.success('Отчет отправлен.');
      initFunc();
      setModalOpen(false);
    } catch (error) {
      console.error('Ошибка при отправке отчета:', error);
      antNotification.error('Ошибка!');
      setModalOpen(false);
    }
  };

  const ReportAlertComponent = useMemo(() => {
    if (loading) {
      return (
        <Spin
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          spinning
        />
      );
    }

    const formattedDate = capitalize(dateToFormat4(lastMonthDate));

    if (reportStatus === 'submitted') {
      if (onlySuccessTask) {
        return (
          <Alert
            message={(
              <>
                <div>
                  Отчет по рабочим дням за прошлый месяц (
                  {formattedDate}
                  ) отправлен!
                </div>
                <Button
                  className="mt-3"
                  type="primary"
                  style={{ border: '#faad14', background: '#52c41a' }}
                  onClick={() => setModalOpen(true)}
                >
                  Скорректировать отчет за прошлый месяц (
                  {formattedDate}
                  )
                </Button>
              </>
                )}
            type="success"
            showIcon
            description={(<div />)}
          />
        );
      }
      return null;
    }

    if (!onlySuccessTask) {
      return (
        <Alert
          message={(
            <>
              <div>
                Вы не отправили отчет за прошлый месяц (
                {formattedDate}
                )!
                Пожалуйста, отправьте отчет!
              </div>
              <Button
                className="mt-3"
                type="primary"
                style={{ border: '#faad14', background: '#ffa500' }}
                onClick={() => setModalOpen(true)}
              >
                Отправить отчет за прошлый месяц (
                {formattedDate}
                )
              </Button>
            </>
              )}
          type="warning"
          showIcon
          description={(<div />)}
        />
      );
    }

    return null;
  }, [reportStatus, onlySuccessTask, loading, lastMonthDate]);

  useEffect(() => {
    if (actor && monthlyStorageUUID && actor === monthlyStorageInfo?.actor) {
      initFunc();
    }
  }, [actor, monthlyStorageUUID, isModalOpen]);

  return (
    <>
      {ReportAlertComponent}
      <UserStatisticsForm
        actor={actor}
        isModalOpen={isModalOpen}
        onCancelModal={() => setModalOpen(false)}
        currentMonth={lastMonthDate}
        onFinish={onFinish}
        formSettings={{
          title: `Отправка отчета за ${capitalize(dayjs(lastMonthDate).format('MMMM, YYYY'))}`,
          totalWorksLabel: capitalize('Отработанные часы: '),
          commentLabel: capitalize('Комментарий:'),
        }}
      />
    </>
  );
}

UserReportForLastMonth.propTypes = {

};
