import React, { useEffect, useState } from 'react';
import {
  Button, Flex, Input, Modal,
} from 'antd';
import { func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  concat, get, head, pick,
  trim,
} from 'lodash';
import cn from 'classnames';
import ListOfUsersHRM54 from './users/ListOfUsersHRM54';
import ListOfCurrentUsers from './users/ListOfCurrentUsers';
import { createDepartment } from '../../../redux/departments/actions/departmentsActions';
import { createArtifact } from '../../../redux/departments/actions/artifactsActions';
import AssigningUserPopover from './users/AssigningUserPopover';
import useAPI from '../../../api/useAPI';
import { getDeliveryPartition } from '../../../redux/config/selectors';

const { TextArea } = Input;

export default function AddDepartmentButton({
  onReloadDepartments,
}) {
  const dispatch = useDispatch();

  const partitionUuid = useSelector(getDeliveryPartition);

  const {
    updateUserEntity,
    createEntityPermissions,
    getDefaultDepartment,
  } = useAPI();

  const [defaultDepartment, setDefaultDepartment] = useState(null);

  const [isCreateModalOpen, setIsModalOpen] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [usersUUIDs, setUsersUUIDs] = useState([]);
  const [users, setUsers] = useState([]);
  const [leader, setLeader] = useState(null);
  const [deputy, setDeputy] = useState(null);

  const btnGreen = 'border border-green-500 text-green-500 hover:bg-green-500 hover:!text-white';
  const btnDisabled = 'border !border-gray-500 text-gray-500 hover:!bg-gray-500 hover:!text-white';
  const btnRed = 'border !border-red-500 text-red-500 hover:bg-red-500 hover:!text-white';

  const resetAll = () => {
    setTitle('');
    setDescription('');
    setUsersUUIDs([]);
    setLeader(null);
    setDeputy(null);
    setUsers([]);
  };

  const onOpenModalCreateDepartment = () => setIsModalOpen(true);
  const onCloseModalCreateDepartment = () => {
    resetAll();
    setIsModalOpen(false);
  };

  const onUserSelect = (value) => {
    if (!usersUUIDs?.includes(value?.actor)) {
      setUsers((prev) => [...prev, value]);
      setUsersUUIDs((prev) => [...prev, value.actor]);
    } else {
      setUsers((prev) => prev.filter((user) => user.actor !== value?.actor));
      setUsersUUIDs((prev) => prev.filter((userUUID) => userUUID !== value?.actor));
    }
  };

  const onAddUserToDepartment = async (user, department) => {
    updateUserEntity(get(user, 'value', ''), {
      parent: get(department, 'uuid', ''),
    });
  };

  const onCreateDepartment = async () => {
    setIsModalOpen(false);

    const newDepartment = {
      params: {
        title,
        description,
      },
    };

    const usersPerms = users.map((user) => ({
      actor: user?.actor,
      create: true,
      list: true,
      read: true,
      update: false,
      delete: false,
      set: false,
    }));

    if (deputy) {
      newDepartment.params.deputy = {
        uuid: get(deputy, ['user', 'uuid'], ''),
        first_name: get(deputy, ['user', 'uinfo', 'first_name'], ''),
        last_name: get(deputy, ['user', 'uinfo', 'last_name'], ''),
        actor: get(deputy, 'actor', ''),
      };
      newDepartment.params.deputyActorUUID = get(deputy, 'actor', '');

      usersPerms.push({
        actor: get(deputy, 'actor', ''),
        create: true,
        list: true,
        read: true,
        update: true,
        delete: true,
        set: false,
      });
    }

    if (leader) {
      newDepartment.params.leader = {
        uuid: get(leader, ['user', 'uuid'], ''),
        first_name: get(leader, ['user', 'uinfo', 'first_name'], ''),
        last_name: get(leader, ['user', 'uinfo', 'last_name'], ''),
        actor: get(leader, 'actor', ''),
      };
      newDepartment.params.leaderActorUUID = get(leader, 'actor', '');

      usersPerms.push({
        actor: get(leader, 'actor', ''),
        create: true,
        list: true,
        read: true,
        update: true,
        delete: true,
        set: true,
      });
    }

    const [department = {}] = await dispatch(createDepartment(partitionUuid, newDepartment));

    users.forEach((el) => {
      onAddUserToDepartment(pick(el, ['value', 'role', 'label', 'actor']), department);
    });

    createEntityPermissions({
      data: {
        entity_uuid: get(department, 'uuid', ''),
        data: usersPerms,
      },
    });

    resetAll();
    onReloadDepartments();
  };

  const onLeaderSelect = (value) => {
    if (value?.actor === leader?.actor) {
      setLeader({});
      return;
    }
    if (value?.actor === deputy?.actor) {
      setDeputy(leader);
    }
    setLeader(value);
  };

  const onDeputySelect = (value) => {
    if (value?.actor === deputy?.actor) {
      setDeputy({});
      return;
    }
    if (value?.actor === leader?.actor) {
      setLeader(deputy);
    }
    setDeputy(value);
  };

  const initFunc = async () => {
    const res = await getDefaultDepartment();
    if (res) {
      setDefaultDepartment(get(res, 'uuid', null));
    }
  };

  useEffect(() => {
    if (partitionUuid) {
      initFunc();
    }
  }, [JSON.stringify(partitionUuid)]);

  return (
    <>
      <Button
        size="small"
        onClick={onOpenModalCreateDepartment}
        className="ml-2 mt-1 !border-blue-500 text-blue-500 hover:bg-blue-500 hover:!text-white"
      >
        Создать отдел
      </Button>
      {isCreateModalOpen && (
      <Modal
        open
        footer={null}
        title="Создать новый отдел"
        destroyOnClose
        onCancel={onCloseModalCreateDepartment}
        width={720}
      >
        <Flex
          vertical
          className="text-stone-500"
        >
          <Flex>
            <span className="text-red-500">*</span>
            Название:
          </Flex>
          <Input
            maxLength={100}
            showCount
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Flex>
        <Flex
          className="mt-3"
          justify="space-between"
        >
          <AssigningUserPopover
            title="Руководитель"
            popoverChildTitle={(
              <span
                className="mr-2 text-stone-500"
              >
                Руководитель:
              </span>
            )}
            parent={partitionUuid}
            selected={leader}
            onUserSelect={onLeaderSelect}
          />
          <AssigningUserPopover
            title="Заместитель"
            placement="leftBottom"
            popoverChildTitle={(
              <span
                className="mr-2 text-stone-500"
              >
                Заместитель:
              </span>
            )}
            parent={partitionUuid}
            selected={deputy}
            onUserSelect={onDeputySelect}
          />
        </Flex>
        <Flex
          className="mt-3"
          justify="space-between"
        >
          <Flex
            vertical
          >
            <span
              className="text-stone-500"
            >
              Пользователи:
            </span>
            <ListOfUsersHRM54
              parent={defaultDepartment}
              selected={usersUUIDs}
              onUserSelect={onUserSelect}
            />
          </Flex>
          <Flex
            vertical
          >
            <span
              className="text-stone-500"
            >
              Сотрудники:
            </span>
            <ListOfCurrentUsers
              users={users}
              onUserChange={onUserSelect}
            />
          </Flex>
        </Flex>

        <Flex
          vertical
          className="mt-3 text-stone-500"
        >
          Сотрудникам:
          <TextArea
            maxLength={8000}
            row={8}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Flex>
        <Flex
          justify="end"
          className="mt-6"
        >
          <Button
            className={`mr-2 ${btnRed}`}
            onClick={onCloseModalCreateDepartment}
          >
            Отмена
          </Button>
          <Button
            className={cn(`${btnGreen}`, {
              [btnDisabled]: !trim(title)?.length,
            })}
            disabled={!trim(title)?.length}
            onClick={onCreateDepartment}
          >
            Создать
          </Button>
        </Flex>
      </Modal>
      )}
    </>
  );
}

AddDepartmentButton.propTypes = {
  parent: string,
  onReloadDepartments: func,
};
