import { get } from 'lodash';

export function convertTimeFormat(input) {
  const regex = /(\d+)h(\d+)m/;
  const match = input.match(regex);

  if (match) {
    const hours = match[1].padStart(2, '0');
    const minutes = match[2].padStart(2, '0');
    return `${hours}:${minutes}`;
  }
  return '';
}

export function compareActorUUIDs(a, b) {
  return get(a, 'actor', {}) === get(b, 'actor', {});
}
