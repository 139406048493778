import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Flex } from 'antd';
import {
  useLocation, useHistory,
} from 'react-router-dom';
import { head } from 'lodash';
import { getAllArtifacts, getSingleArtifact } from '../../../redux/departments/actions/artifactsActions';
import { getProfileInfo } from '../../../redux/profile/selectors';
import { RESET_SINGLE_ARTIFACT } from '../../../redux/departments/slices/artifactsSlice';
import PageWrapper from '../../../components/PageWrapper';
import { getAllDepartments } from '../../../redux/departments/actions/departmentsActions';
import { getDeliveryPartition } from '../../../redux/config/selectors';
import ArtifactCardForMyDep from '../../admin/departments/artifacts/ArtifactCardForMyDep';
import useAPI from '../../../api/useAPI';
import { entityRead } from '../../../api/actions/entity';
import { SERVICE_PARTITION_NAME } from '../../../api/app_config';
import MyDepartmentCard from './MyDepartmentCard';

export default function MyDepartmentPage() {
  const dispatch = useDispatch();

  const myProfileInfo = useSelector(getProfileInfo);

  const partitionUUID = useSelector(getDeliveryPartition);

  const [myCard, setMyCard] = useState(null);
  const [myDepartment, setMyDepartment] = useState(null);

  const {
    readRootUser,
  } = useAPI();

  const initFunc = async () => {
    try {
      const res = await readRootUser();

      const user = res?.data?.[0] ?? null;

      setMyCard(user);

      if (!user) {
        return;
      }

      const department = await dispatch(entityRead({
        data: {
          entity_type: 'department',
          entity_uuid: user?.parent,
          parent: partitionUUID,
        },
        constants: [
          'GET_DEPARTMENT_REQUEST',
          'GET_DEPARTMENT_SUCCESS',
          'GET_DEPARTMENT_FAILURE',
        ],
        partition: SERVICE_PARTITION_NAME,
      }));

      if (head(department)?.entity_type === 'department') {
        setMyDepartment(head(department) ?? null);
      }
    } catch (e) {
      console.log(e);
      setMyCard(null);
      setMyDepartment(null);
    }
  };

  useEffect(() => {
    if (partitionUUID && myProfileInfo?.uuid) {
      initFunc();
    }
  }, [partitionUUID, myProfileInfo?.uuid]);

  return (
    <PageWrapper
      title={(
        <Flex
          className="-mb-5 text-2xl"
        >
          Мой отдел
        </Flex>
      )}
      className="w-full"
    >
      <Flex
        justify="space-between"
      >
        {myCard && (
        <Flex className="w-1/2">
          <ArtifactCardForMyDep
            title="Моя карточка"
            artifact={myCard}
            disabledFull
            artifactUUID={myCard?.uuid}
            onUpdateArtifactCallback={() => {}}
            onDeleteArtifactCallback={() => {}}
            onBackCallback={() => {}}
            onReloadListCallback={() => {}}
          />
        </Flex>
        )}
        {myDepartment ? (
          <Flex className="w-1/2 ml-6">
            <MyDepartmentCard
              department={myDepartment}
              departmentUUID={myDepartment?.uuid}
            />
          </Flex>
        ) : (
          <Alert
            className="w-1/2 ml-6 h-max"
            type="warning"
            showIcon
            message={(
              <span
                className="text-lg"
              >
                Вы не состоите в отделе! Обратитесь к руководителю для добавления вас в отдел.
              </span>
)}
          />
        )}
      </Flex>
    </PageWrapper>
  );
}
