import React from 'react';

import { get } from 'lodash';

import { Flex, Tooltip } from 'antd';

import Icon from '@mdi/react';
import { mdiAlertCircleOutline } from '@mdi/js';
import { object, string } from 'prop-types';

export default function EmployeeNameForDepartment({
  user = { },
  deputy = '',
  leader = '',
  onClick,
}) {
  const isLeader = leader === user?.actor;
  const isDeputy = deputy === user?.actor;

  const getPartOfText = (text) => (
    <Tooltip
      title={text}
    >
      <span
        style={{
          wordBreak: 'break-word',
        }}
      >
        {text?.length > 20 ? `${text.slice(0, 20)}...` : text}
      </span>
    </Tooltip>
  );

  return (
    <Flex
      onClick={() => {
        if (onClick) {
          onClick?.(user?.actor, user);
        }
      }}
    >
      {(isLeader || isDeputy) && (
        <Tooltip
          title={isLeader ? 'Руководитель' : 'Заместитель'}
          color={isLeader ? 'red' : 'orange'}
        >
          <Icon
            className="mr-1"
            path={mdiAlertCircleOutline}
            color={isLeader ? 'red' : 'orange'}
            size={1}
          />
        </Tooltip>
      )}
      {getPartOfText(`${get(user, 'uinfo.first_name', '')} ${get(user, 'uinfo.last_name', '')}`)}
    </Flex>
  );
}

EmployeeNameForDepartment.propTypes = {
  user: object,
  deputy: string,
  leader: string,
  onClick: string,
};
