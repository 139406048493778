import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import { string } from 'prop-types';
import { defaultConfig } from './constants';
import { getProfileInfo } from '../../../redux/profile/selectors';
import useAPI from '../../../api/useAPI';
import { enumerateDaysBetweenDates } from '../../../utils/commonUtils';
import CalendarDayOff from './calendarDayOff/CalendarDayOff';
import { getArrByType, getFirstAndLastDayCalendar } from './utils';
import paramsURL from '../../../utils/ParamsURL';
import { getAllVacations } from '../../../redux/vacations/selectors';
import { getMonthlyStorageInfo, getMonthlyStorageUUID, getVacationStorageUUID } from '../../../redux/storages/selectors';
import antNotification from '../../../utils/antNotification';
import MonthlyReportConstants from '../../../redux/monthlyReport/constants';
import { getFetchingMonthlyReport, getMonthlyReportDays, getMonthlyReportUUID } from '../../../redux/monthlyReport/selectors';

function DayOffPage({ actor }) {
  const {
    getOrCreateMonthlyReport,
    updateDayInMonthlyReport,
    getAgreedVacations,
  } = useAPI();

  const vacationStorageUUID = useSelector(getVacationStorageUUID);
  const monthlyStorageUUID = useSelector(getMonthlyStorageUUID);
  const monthlyStorageInfo = useSelector(getMonthlyStorageInfo);

  const vacations = useSelector(getAllVacations);
  const userDayOffs = useSelector(getMonthlyReportDays);
  const monthlyReportUUID = useSelector(getMonthlyReportUUID);
  const isFetching = useSelector(getFetchingMonthlyReport);

  const [config, setConfig] = useState(defaultConfig);

  const {
    firstDayCalendar,
    lastDayCalendar,
  } = useMemo(() => (
    getFirstAndLastDayCalendar(dayjs(config.startDate))
  ), [config.startDate]);

  const getListDayOffs = async () => {
    const configRequest = {
      actor,
      params: {
        date: dayjs(config.startDate).format('YYYY-MM'),
      },
      params_fields: {
        actorUuid: 'actorUuid',
        date: 'date',
        days: 'days',
      },
    };

    const constants = [
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_REQUEST,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_SUCCESS,
      MonthlyReportConstants.GET_OR_CREATE_MONTHLY_REPORT_FAILURE,
    ];

    await getOrCreateMonthlyReport(monthlyStorageUUID, configRequest, constants);
  };

  const getListAgreedVacations = () => {
    const configRequest = {
      actor,
      params: {
        status: 'approved',
        rangeDates: enumerateDaysBetweenDates(
          dayjs(firstDayCalendar).format('YYYY-MM-DD'),
          dayjs(lastDayCalendar).format('YYYY-MM-DD'),
        ),
      },
    };
    return getAgreedVacations(vacationStorageUUID, configRequest);
  };

  const onChangeDate = (date) => {
    const DATE = dayjs(date);

    setConfig({
      startDate: DATE.format('YYYY-MM-DD'),
      endDate: DATE.endOf('month').format('YYYY-MM-DD'),
    });

    paramsURL.set({ month: DATE.format('YYYY-MM') });
  };

  const updateDayCallback = async (type, dayDate, options = {}) => {
    try {
      const newDay = {
        actorUuid: actor,
        type,
        created: dayjs().toString(),
        rangeDates: [dayjs(dayDate).format('YYYY-MM-DD')],
        wasChoiceOfDay: true,
        ...options,
      };

      const oldDay = userDayOffs.find(({ rangeDates }) => rangeDates[0] === dayjs(dayDate).format('YYYY-MM-DD'));

      if (oldDay) {
        newDay.logs = [{
          ...omit(oldDay, 'logs'),
        }, ...(oldDay?.logs ?? [])];
      }

      const newDays = [...userDayOffs].filter(({ rangeDates }) => rangeDates[0] !== dayjs(dayDate).format('YYYY-MM-DD'));

      await updateDayInMonthlyReport(monthlyReportUUID, [...newDays, newDay]);
      await getListDayOffs();
      antNotification.success('День изменен!');
    } catch (e) {
      antNotification.error('Ошибка!');
    }
  };

  const getPrevDayCallback = (currentDate = dayjs()) => {
    const sorteredDays = userDayOffs ?? [];

    if (!sorteredDays?.length) return null;

    return sorteredDays
      ?.sort((a, b) => dayjs(a?.rangeDates?.[0]).unix() - dayjs(b?.rangeDates?.[0]).unix())
      ?.reduce((acc, item) => {
        if (item?.hoursWorked && dayjs(item?.rangeDates?.[0]).isBefore(dayjs(currentDate))) {
          return item;
        }
        return acc;
      }, null) ?? null;
  };

  const data = useMemo(() => getArrByType([
    ...vacations, ...userDayOffs,
  ], enumerateDaysBetweenDates(config.startDate, config.endDate)), [
    JSON.stringify(vacations),
    JSON.stringify(userDayOffs),
    JSON.stringify(config),
    actor,
  ]);

  useEffect(() => {
    paramsURL.set({ month: dayjs(config.startDate).format('YYYY-MM') });

    if (actor
      && vacationStorageUUID
      && monthlyStorageUUID
      && actor === monthlyStorageInfo?.actor) {
      getListDayOffs();
      getListAgreedVacations();
    }
  }, [
    actor,
    vacationStorageUUID,
    firstDayCalendar,
    lastDayCalendar,
    monthlyStorageUUID,
  ]);

  return (
    <CalendarDayOff
      getPrevDayCallback={getPrevDayCallback}
      actor={actor}
      dataSource={data}
      startDate={config.startDate}
      loading={isFetching}
      changeCurrentDayCallback={onChangeDate}
      saveCallback={getListDayOffs}
      updateDayCallback={updateDayCallback}
    />
  );
}

export default DayOffPage;

DayOffPage.propTypes = {
  customActor: string,
};
